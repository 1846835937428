<template>
  <div v-if="spacesWithCoordinates.length === 0" style="width:100%">
    <hb-empty-state>
      <div v-if="!mapInitialLoad">Please select a Property to continue.</div>
      <div v-else>
        The Property Map for this property has not been set up.
        <br />
        Please contact Customer Success (success@tenantinc.com) to configure your property map.
      </div>
    </hb-empty-state>
  </div>
  <div v-else class="map-wrap">
    <div
      class="map-layer"
      :style="`transform: translate(${backgroundX}px, ${backgroundY}px) scale(${mapScaleFactor});`"
    >
      <div
        v-for="(space, i) in spacesWithCoordinates"
        :class="[
          `space-entity`,
          `state-${space.state ? colorCodes[space.state.toLowerCase()] : ''}`,
          space.floor == currentFloor ? 'active-floor' : '',
          space.floor == 1 ? 'base-floor' : '',
          space.filterMatch ? 'filter-match' : 'filter-out',
          'floor-layer',
        ]"
        :key="i"
        :data-id="space.id"
        :data-floor="space.floor"
        @click.stop="toggleSpaceModal(space.id)"
        :style="`width: ${space.width * 10}px;
           height: ${space.length * 10}px;
           transform: rotate(${space.rotate}deg);
           top: ${space.y * 10}px;
           left: ${space.x * 10}px;
           color: ${space.state == 'available' ? '#fff' : '#000'};
           background-color: ${space.filterMatch ? space.color : '#fff'};`"
      >
        <div
          class="map-space-label"
          :style="`transform: rotate(${-space.rotate}deg)`"
        >
          {{ space.number }}
          <div
            class="space-quick-card"
            v-if="space.filterMatch"
            :style="`transform: scale(${1 / mapScaleFactor})`"
          >
            <div class="space-chip">
              <hb-status @emitColor="addStateColor($event, i)">{{
                space.state
              }}</hb-status>
            </div>
            <p class="space-id">{{ space.number }}</p>
            <p>{{ space.width }}` x {{ space.length }}`</p>
            <p>Set Rate: ${{ space.price ? space.set_rate.toFixed(2) : "" }}</p>
            <p>{{ space.topAmenity }}</p>
          </div>
        </div>
      </div>
      <div
        v-for="asset in assets"
        :class="[
          `space-entity asset-entity`,
          asset.floor == currentFloor ? 'active-floor' : '',
          asset.floor == 1 ? 'base-floor' : '',
          'floor-layer',
        ]"
        :key="asset.id"
        :data-id="asset.id"
        :style="`width: ${asset.width}px;
           height: ${asset.length}px;
           transform: rotate(${asset.rotate}deg);
           top: ${asset.y * 10}px;
           left: ${asset.x * 10}px;`"
      >
        <div
          class="map-asset-label"
          :style="`transform: rotate(${-asset.rotate}deg)`"
        >
          <span v-if="asset.asset === 'office'">{{ asset.asset }}</span>
          <span v-else><hb-icon>mdi-{{ asset.asset }}</hb-icon></span>
        </div>
      </div>
    </div>

    <div
      class="background-layer"
      :style="`
         background-position: ${backgroundX}px ${backgroundY}px;
         background-size: ${50 * mapScaleFactor}px ${50 * mapScaleFactor}px;`"
    >
      <vue-draggable-resizable
        :grid="[10, 10]"
        :w="50"
        :h="50"
        :x="backgroundX"
        :y="backgroundY"
        :resizable="false"
        @dragging="backgroundMove"
        :style="`opacity:0;transform: translate(${backgroundElementOffsetX}px, ${backgroundElementOffsetY}px);`"
      >
        <div
          :style="`
        width: 100vw;
        height: 100vh;
      `"
        ></div>
      </vue-draggable-resizable>
    </div>
    <div class="float-nav">
      <div class="zoom-controls">
        <button @click="zoomIn()">
          <v-icon>mdi-plus</v-icon>
        </button>
        <button @click="zoomOut()">
          <v-icon>mdi-minus</v-icon>
        </button>
        <button @click="zoomReset()">
          <v-icon>mdi-image-filter-center-focus</v-icon>
        </button>
      </div>
    </div>
    <div class="bottom-bar">Spaces: {{ spaceCount }}</div>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";

export default {
  name: "MapViewer",
  components: { VueDraggableResizable },
  data: () => ({
    backgroundX: 0,
    backgroundY: 0,
    backgroundElementOffsetX: 0,
    backgroundElementOffsetY: 0,
    mapScaleFactor: 1,
    colorCodes: {
      available: "gray",
      leased: "green",
      reserved: "yellow",
      pending: "yellow",
      unavailable: "red",
    },
  }),
  props: {
    mapInitialLoad: Boolean,
    spacesWithCoordinates: Array,
    assets: Array,
    floors: Array,
    currentFloor: Number,
    spaceCount: Number,
  },
  mounted: function () {
    this.$nextTick(() => {
      this.centerAndScaleMap()
    })
    let viewableArea = document.querySelector(".background-layer");
    this.backgroundX = viewableArea && viewableArea.offsetWidth / 2;
    this.backgroundY = viewableArea && viewableArea.offsetHeight / 2.5;
  },
  methods: {
    zoomIn() {
      this.mapScaleFactor = this.mapScaleFactor * 1.2;
      this.backgroundX = this.backgroundX * 1.2;
      this.backgroundY = this.backgroundY * 1.2;
    },
    zoomOut() {
      this.mapScaleFactor = this.mapScaleFactor * (1.0 / 1.2);
      this.backgroundX = this.backgroundX * (1.0 / 1.2);
      this.backgroundY = this.backgroundY * (1.0 / 1.2);
    },
    zoomReset() {
       this.centerAndScaleMap();
    },  
    centerAndScaleMap() {
      let { currentFloor } = this;
      let spaces = this.spacesWithCoordinates.filter(
        (space) => space.floor == currentFloor
      );
      let assets = this.assets.filter((asset) => asset.floor == currentFloor);

      if (!spaces.length) return;

      let leftVals = [], topVals = [];
      spaces.map((space) => {
        if (space.floor == currentFloor && space.x != null && space.y != null) {
          leftVals.push(space.x * 10);
          topVals.push(space.y * 10);
        }
      });
      assets.map((asset) => {
        if (asset.floor == currentFloor && asset.x != null && asset.y != null) {
          leftVals.push(asset.x * 10);
          topVals.push(asset.y * 10);
        }
      });
      let visibleWidth = Math.abs(Math.max(...leftVals)) + Math.abs(Math.min(...leftVals));
      let visibleHeight = Math.abs(Math.max(...topVals)) + Math.abs(Math.min(...topVals));
      
      let layer = document.querySelector(".map-layer");
      let layerViewportWidth = layer.offsetWidth;
      let layerViewportHeight = layer.offsetHeight;

      // Calculate the scaling factor to fit the map within the viewport
      let scaleX = layerViewportWidth / visibleWidth;
      let scaleY = layerViewportHeight / visibleHeight;
      // Slightly reduce the scale factor to zoom out
      if(scaleX > 5 || scaleY > 5){
        this.mapScaleFactor = Math.min(scaleX, scaleY) * 0.2;
      }else if(scaleX > 3 || scaleY > 3){
        this.mapScaleFactor = Math.min(scaleX, scaleY) * 0.5; // Zoom out by 50%
      }else if(scaleX > 0.1 || scaleY > 0.1){
        this.mapScaleFactor = Math.min(scaleX, scaleY) * 0.7;
      }else {
        this.mapScaleFactor = Math.min(scaleX, scaleY) * 0.85; // Zoom out by 15%
      }
      
      // Calculate the centered position after scaling with padding
      this.backgroundX =
        (layerViewportWidth - (visibleWidth * this.mapScaleFactor)) / 2 +
        Math.abs(Math.min(...leftVals)) * this.mapScaleFactor; 
      this.backgroundY =
        ((layerViewportHeight - (visibleHeight * this.mapScaleFactor)) / 2) +
        Math.abs(Math.min(...topVals)) * this.mapScaleFactor; // Add 50px padding



    },
    backgroundMove: function (x, y) {
      this.backgroundX = x;
      this.backgroundY = y;
    },
    toggleSpaceModal(entity_id) {
      let params = {
        component: "unit",
        props: { unit_id: entity_id },
      };
      this.$store.dispatch("navigationStore/openSlideOut", params);
    },
    addStateColor(color, i) {
      // Per TI-11411 we have adjusted the colors slightly for the map view
      switch (color.toLowerCase()) {
        case "#3d8fff": //Change blue to map-specific blue
          color = "#5caaff";
          break;
        case "#02ad0f": //Change green to map-specific green
          color = "#44c73f";
          break;
        case "#fb4c4c": //Change red to map-specific red
          color = "#fe6866";
          break;
      }
      this.spacesWithCoordinates[i].color = color;
    },
  },
  watch: {
    currentFloor() {
      this.centerAndScaleMap();
    },
    mapInitialLoad() {
      this.centerAndScaleMap();
    }
  }
};
</script>

<style lang="scss">
.no-spaces {
  width: 100%;
  height: 96px;
  padding: 16px;
  border: 1px dashed #cdcdcd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #637381;
  text-align: center;
}

.map-wrap {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0.5px 0.75px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);

  & > div {
    display: block;
    height: 100%;
    position: absolute;
    user-select: none;
  }

  & .map-layer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    transform-origin: top left;

    & .space-entity {
      position: absolute;
      border: 0.5px solid #000;
      box-shadow: 0 0 0px 0.5px #000;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      cursor: pointer;
      user-select: none;
      pointer-events: auto;
      background-color: #637381;

      &.asset-entity {
        background: white;
        cursor: auto;
        text-transform: capitalize;
        font-weight: 500;
      }

      & .map-space-label {
        max-width: 100%;
        overflow-wrap: break-word;
        font-weight: 500;
      }

      &.floor-layer {
        opacity: 0;
        pointer-events: none;
        transition: opacity 1.35s, background-color 0.35s 0.35s;
      }

      &.base-floor {
        pointer-events: none;
      }

      &.active-floor {
        opacity: 1;
        pointer-events: auto;

        &.filter-out {
          opacity: 0.4;
          pointer-events: none;
          cursor: default;
        }
      }

      &.state-green {
        background-color: #02ad0f;
      }

      &.state-yellow {
        background-color: #ffd600;
      }

      &.state-red {
        background-color: #fb4c4c;
      }

      &:hover {
        z-index: 8;

        .space-quick-card {
          display: block;
        }
      }

      & .space-quick-card {
        display: none;
        position: absolute;
        width: 200px;
        height: 88px;
        background: #334450;
        color: white;
        padding: 8px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 130%;
        transform-origin: top left;

        & .space-chip {
          float: right;

          & .color {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #637381;
            margin-bottom: -3px;

            &.state-green {
              background-color: #02ad0f;
            }

            &.state-yellow {
              background-color: #ffd600;
            }

            &.state-red {
              background-color: #fb4c4c;
            }
          }

          & .availability {
            margin-left: 5px;
            font-size: 12px;
            line-height: 16px;
            color: #101318;
            text-transform: capitalize;
          }
        }

        & p {
          margin: 0;
        }

        & .space-id {
          font-size: 14px;
          line-height: 170%;
        }
      }
    }
  }

  & .background-layer {
    top: 0;
    left: 0;
    position: relative !important;
    z-index: 0;
    background-repeat: repeat;
    background-image: linear-gradient(to right, #dadada 1px, transparent 1px),
      linear-gradient(to bottom, #dadada 1px, transparent 1px);
  }

  & .float-nav {
    position: absolute;
    display: block;
    top: 24px;
    left: 24px;
    width: 56px;
    height: 144px;
    box-shadow: 0px 0.5px 0.75px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 4;

    & .zoom-controls {
      background: #fff;
      pointer-events: auto;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      align-items: space-between;
      box-shadow: 0px 0.5px 0.75px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      height: 100%;
    }
  }

  & .bottom-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
    z-index: 4;
  }
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

</style>
