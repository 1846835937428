export default Object.freeze({
  TYPES: {
    month_to_month: {
      text: 'Month to Month',
      value: 'Month to Month',
    },
    fixed_length: {
      text: 'Fixed Length',
      value: 'Fixed Length',
    },
  },
  STATUSES: {
    RESERVED: 'reserved',
    LEASED: 'leased',
    PENDING: 'pending',
    CLOSED: 'closed',
    OFFLINE: 'offline'
  },
  PAYMENT_CYCLES: {
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    ANNUAL: 'Annual'
  },
  RETIRE_LEAD_REASONS: [
    "Stored With Competitor",
    "Priced Too high",
    "Space/Amenities Didn't Meet Need",
    "No Longer Interested",
    "Can't afford storage",
    "Not a lead",
    "Duplicate"
  ],
  TOOLTIPS_MESSAGES: {
    PERMISSION: 'User is not allowed to perform reverse move-out.',
    AUCTION_MOVE_OUT: 'The move-out due to an auction cannot be reversed.',
    UNIT_AVAILABILITY: 'The unit is currently unavailable.',
    UNABLE_TO_REVERSE: 'User cannot perform a reverse move-out either due to unfulfilled or disabled settings, or because the unit is occupied by another tenant.',
  }
});
