<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div
        class="hb-communication-wrapper"
        :class="{'hb-communication-wrapper-active' : active, 'hb-communication-wrapper-preview' : preview, 'hb-communication-wrapper-elevation': elevation, 'hb-pointer' : !active && !preview}"
        @click.capture="$emit('click', payload)"
        v-resize="calculateExpandability"
        ref="hbCommunicationRef"
    >
        <div
            class="hb-communication-background hb-communication-content-wrapper px-3 pb-3"
            :class="{ 'hb-communication-content-condensed-preview-set-min-height-less-than-three' : preview && infoLineEntries < 3, 'hb-communication-content-condensed-preview-set-min-height-three-plus' : preview && infoLineEntries > 2, 'hb-communication-content-condensed-not-preview-set-min-height' : !preview && !expanded && infoLineEntries < 3, 'hb-communication-content-condensed-not-preview-set-min-height-three-plus' : !expanded && !preview && infoLineEntries > 2 }"
        >
            <v-row v-if="icon || title || time" no-gutters class="mt-1 hb-communication-header">
                <v-col :cols="((type === 'document' && alternateContact) || (type === 'note' && noteCategory)) && preview ? 6 : 5" no-gutters class="pr-2 hb-communication-overflow-handler" style="position:relative;top:1.5px;">
                    <hb-icon small :color="setIconColor" :mdi-code="setIcon" :style="type === 'text in' || type === 'text out' ? 'position:relative;top:-1px;' : 'position:relative;top:-1.5px;'"></hb-icon>
                    <span class="font-weight-medium pl-2" :style="'color:' + setTitleColor">{{ setTitle }}</span>
                    <span v-if="type === 'note' && noteCategory" class="font-weight-medium pl-1">({{ noteCategory }})</span>
                    <span v-if="alternateContact">
                        <span class="hb-communication-alternate-contact-dash-divider font-weight-medium">-</span>
                        <hb-tooltip>
                            <template v-slot:item>
                                <hb-icon small color="#101318" mdi-code="mdi-account-multiple-outline"></hb-icon>
                                <span v-if="!preview && alternateContactType" class="px-1 font-weight-medium">{{ alternateContactType }}</span>
                            </template>
                            <template v-slot:header>
                                {{ alternateContactName }}
                            </template>
                            <template v-slot:body>
                                <div v-if="alternateContactType">{{ alternateContactType }}</div>
                                <div v-if="alternateContactEmail">{{ alternateContactEmail }}</div>
                                <div v-if="alternateContactPhone">{{ alternateContactPhone }}</div>
                            </template>
                        </hb-tooltip>
                    </span>
                </v-col>
                <v-col :cols="((type === 'document' && alternateContact) || (type === 'note' && noteCategory)) && preview ? 6 : 7" no-gutters class="d-flex justify-end align-center">
                    <span class="hb-communication-text-night-light hb-communication-font-caption mt-1 hb-communication-overflow-handler" style="margin-right:2px;">
                        {{ time }}
                    </span>
                    <hb-tooltip v-if="hasImages">
                        <template v-slot:item>
                            <hb-icon
                                small
                                style="margin-left:1px;position:relative;top:1px;"
                            >
                                mdi-image
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            Has Image(s)
                        </template>
                    </hb-tooltip>
                    <hb-tooltip v-if="hasAttachments">
                        <template v-slot:item>
                            <hb-icon
                                small
                                :style="(preview || disablePinning) && !hasNotes && !unread ? 'left:2px;margin-right:-3px;position:relative;top:1px;' : 'margin-left:1px;position:relative;top:1px;'"
                            >
                                mdi-paperclip
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            Has Attachment(s)
                        </template>
                    </hb-tooltip>
                    <hb-tooltip v-if="hasNotes">
                        <template v-slot:item>
                            <hb-icon
                                small
                                style="margin-left:3px;margin-right:-2px;position:relative;top:1px;"
                            >
                                mdi-content-paste
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            Has Note(s)
                        </template>
                    </hb-tooltip>
                    <hb-tooltip v-if="!preview && !disablePinning">
                        <template v-slot:item>
                            <hb-icon
                                small
                                pointer
                                @click="$emit('pinned-icon-clicked', payload)"
                                :style="hasNotes ? 'margin-left:3px;margin-right:-3px;position:relative;top:2px;' : 'margin-left:0px;margin-right:-3px;position:relative;top:2px;'"
                                :mdi-code="pinned ? 'mdi-pin' : 'mdi-pin-outline'"
                            >
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            {{ pinned ? 'Unpin' : 'Pin' }}
                        </template>
                    </hb-tooltip>
                    <hb-tooltip v-if="unread">
                        <template v-slot:item>
                            <div
                                class="hb-communication-unread-dot"
                                :style="hasNotes && (preview || disablePinning) ? 'margin-left:6px;position:relative;top:3px;' : 'margin-left:4px;position:relative;top:3px;'"
                            >
                                &nbsp;
                            </div>
                        </template>
                        <template v-slot:body>
                            Unread
                        </template>
                    </hb-tooltip>
                    <hb-tooltip v-if="status && !disableStatusPreview && ((type == 'email out' || type == 'text out' || type == 'system email' || type == 'system text') || (preview && type == 'document'))" max-width="400">
                        <template v-slot:item>
                            <HbStatusGeneral
                                preview
                                :status="status"
                                :type="statusType"
                                @emitColor="communicationInfoColor = $event"
                                :style="!unread && !preview && !disablePinning ? 'margin-left:-2px;position:relative;top:2px;right:-5px;' : 'margin-left:1px;position:relative;top:2px;right:-5px;'"
                            />
                        </template>
                        <template v-slot:header>
                            <span class="text-capitalize">{{ status }}</span>
                        </template>
                        <template v-slot:body>
                            <span class="text-capitalize" v-if="statusTime">{{ statusTime }}<br /></span>
                            <span class="text-capitalize" v-if="status == 'Bounced' || status == 'Error' || status == 'Failed' || status == 'Resolved'">{{ errorDescription }}</span>
                        </template>
                    </hb-tooltip>
                    <hb-tooltip v-if="dismissable && preview">
                        <template v-slot:item>
                            <div style="position:relative;top:1px;right:-7px;margin-left:-5px;margin-bottom:-3px;">
                                <hb-icon @click="$emit('dismiss', payload)">mdi-close</hb-icon>
                            </div>
                        </template>
                        <template v-slot:body>
                            Dismiss
                        </template>
                    </hb-tooltip>
                </v-col>
            </v-row>

            <v-row no-gutters class="hb-communication-font-caption mt-1 hb-communication-content">
                <v-col :cols="preview || !expandable ? 12 : 11">
                    <div
                        class="hb-communication-info-wrapper"
                        :class="{ 'hb-communication-info-wrapper-overflow-handler' : !expanded || preview, 'hb-communication-info-not-preview' : !preview, 'mt-2' : !preview && infoLineEntries > 1, 'hb-communication-info-condensed-wrapper' : !expanded }"
                        ref="hbCommunicationInfoRef"
                    >
                        <div v-if="alternateContact" no-gutters class="hb-communication-font-caption">
                            <span class="font-weight-medium">{{ type == 'email in' || type == 'text in' || type == 'call in' || type == 'missed' || type == 'voicemail' || type == 'reservation' ? 'From' : 'To' }}: </span>
                            <span class="hb-communication-text-night-light">{{ alternateContactName ? alternateContactName : 'Unknown' }}</span>
                            <span v-if="preview && alternateContactType" class="pl-1 hb-communication-text-night-light">({{ alternateContactType }})</span>
                        </div>
                        <div v-if="documentName && type == 'document'" no-gutters class="hb-communication-font-caption">
                            <span class="hb-communication-text-night-light font-weight-medium">{{ documentName }}</span>
                        </div>
                        <div v-if="deliveredBy && (type == 'system email' || type == 'document')" no-gutters class="hb-communication-font-caption">
                            <span class="font-weight-medium">Delivered by: </span>
                            <span class="hb-communication-text-night-light">{{ deliveredBy }}</span>
                        </div>
                        <div v-if="deliveryMethod && (type == 'system email' || type == 'document')" no-gutters class="hb-communication-font-caption">
                            <span class="font-weight-medium">Delivery Method: </span>
                            <span class="hb-communication-text-night-light">{{ deliveryMethod }}</span>
                        </div>
                        <div v-if="property && (type == 'call in' || type == 'call out' || type == 'voicemail' || type == 'missed' || type == 'reservation')" no-gutters class="hb-communication-font-caption">
                            <span class="font-weight-medium">Property: </span>
                            <span class="hb-communication-text-night-light">{{ property }}</span>
                        </div>
                        <div v-if="(space || subject) && !preview" no-gutters class="hb-communication-font-caption font-weight-medium hb-communication-text-night-light">
                            <span v-if="space && spaceType" style="margin-right:1px;">
                                <HbSpaceIcon small :type="spaceType" enable-tooltip />
                            </span>
                            <span v-if="space">
                                {{ space }}
                            </span>
                            <span v-if="space && subject && !preview && (type == 'system email' || type == 'email in' || type == 'email out')" class="px-1">
                                -
                            </span>
                            <span v-if="subject && (type == 'system email' || type == 'email in' || type == 'email out')">{{ subject }}</span>
                        </div>
                        <div v-if="subject && (type == 'system email' || type == 'email in' || type == 'email out') && preview" no-gutters class="hb-communication-font-caption font-weight-medium hb-communication-text-night-light">
                            {{ subject }}
                        </div>
                        <div v-if="source && (type == 'call in' || type == 'call out' || type == 'voicemail' || type == 'missed' || type == 'reservation')" no-gutters class="hb-communication-font-caption">
                            <span class="font-weight-medium">Source: </span>
                            <span class="hb-communication-text-night-light">{{ source }}</span>
                        </div>
                        <div v-if="notes && (type == 'call in' || type == 'call out')" no-gutters class="hb-communication-font-caption">
                            <span class="font-weight-medium hb-communication-info-notes-title">Notes: </span>
                            <span class="hb-communication-text-night-light hb-communication-info-notes-content">{{ notes }}</span>
                        </div>
                        <div
                            v-if="$slots.default"
                            class="hb-communication-text-night-light hb-communication-default-slot-wrapper"
                            :class="{ 'mt-3' : !preview && infoLineEntries > 1, 'hb-communication-default-slot-preview-overflow' : preview }"
                            ref="hbCommunicationInfoSlotRef"
                        >
                            <slot></slot>
                        </div>
                    </div>
                </v-col>
                <v-col cols="1" style="position:relative;" v-if="expandable && !preview">
                    <div class="hb-communication-expand-icon" :class="expandIconClass">
                        <hb-tooltip>
                            <template v-slot:item>
                                <HbIcon pointer @click="toggleExpanded()" :mdi-code="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
                            </template>
                            <template v-slot:body>
                                {{ expanded ? 'Collapse' : 'Expand' }}
                            </template>
                        </hb-tooltip>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div
            v-if="preview"
            class="hb-communication-contact-info-wrapper hb-communication-contact-info-border-radius px-3"
            :class="contactInfoBackgroundClass"
        >

            <v-row no-gutters>
                <v-col cols="12" no-gutters class="hb-communication-overflow-handler py-1 d-flex align-center">

                    <div
                        v-if="outgoing"
                        class="font-weight-medium pr-2"
                        style="margin-top:2px;"
                    >
                        To:
                    </div>

                    <div v-if="spaceType" style="margin-top:3px;">
                        <HbSpaceIcon small :type="spaceType" color="#101318" :class="{ 'pr-1' : space }" enable-tooltip />
                    </div>

                    <div v-if="space" class="font-weight-medium pr-2" style="margin-top:3px;">
                        {{ space }}
                    </div>

                    <HbIcon v-if="contactIsBusiness" color="#101318" small mdi-code="mdi-domain" />
                    <HbIcon v-else color="#101318" small mdi-code="mdi-account" />

                    <div class="font-weight-medium pl-2 hb-communication-overflow-handler" :style="outgoing ? 'margin-top:3px;max-width:150px;' : 'margin-top:3px;max-width:170px;'">{{ contactName }}</div>
                    
                    <v-spacer></v-spacer>
                    
                    <HbStatus
                        class="pl-2"
                        @emitColor="contactInfoBackgroundColor = $event"
                        style="position:relative;top:1.5px;"
                        :status="contactStatus"
                        :pointer="preview"
                    />
                </v-col>
            </v-row>

        </div>

        <div
            v-if="!preview && !hideActions"
            class="hb-communication-actions-wrapper hb-communication-contact-info-border-radius px-3"
            :class="[contactInfoBackgroundClass, errorDescription && !hideErrorDescription ? 'hb-communication-contact-info-border-radius' : '']"
        >

            <v-row no-gutters>
                <v-col cols="6" no-gutters class="py-2">

                    <v-row no-gutters v-if="$slots.leftActions" class="hb-communication-text-night-light" :style="leftActionsSlotAttachments ? 'margin-top: -2px;margin-bottom:-2px;' : ''">
                        <slot name="leftActions"></slot>
                    </v-row>
                    <div v-else>
                        <div v-if="(type == 'call in' || type == 'call out') && playback && !hidePlayback" class="d-flex align-top">
                            <hb-btn
                                icon
                                active-by-default
                                always-active
                                active-background-color="#637381"
                                color="#ffffff"
                                active-color="#ffffff"
                                hover-color="#ffffff"
                                hover-opacity
                                :disabled="!playbackLoaded"
                                @click="playbackPlayPause()"
                                :tooltip="!playbackLoaded ? 'Please Wait - Call Playback is Loading' : 'Play/Pause Call Playback'"
                            >
                                mdi-play-pause
                            </hb-btn>

                            <div class="pl-1">
                                <div class="hb-communication-font-caption">Call Playback</div>
                                <div class="hb-communication-font-caption hb-communication-footer-element-wrapper">
                                    <div v-if="$slots.playbackTime">
                                        <slot name="playbackTime"></slot>
                                    </div>
                                    <div v-else>
                                        {{ playbackCurrentTime }}<span class="hb-communication-text-night-light">/{{ playbackTotalTime }}</span>
                                    </div>
                                </div>
                            </div>

                            <audio
                                style="display:none;"
                                ref="playback"
                                controls
                                :src="playback"
                                preload
                                @canplay="playbackLoaded = true"
                                @ended="playbackPaused = true"
                                @loadedmetadata="updateTotalTime('playback')"
                                @timeupdate="onTimeUpdate('playback')"
                            ></audio>
                        </div>

                        <div v-else-if="type == 'voicemail' && voicemail && !hideVoicemail" class="d-flex align-top">
                            <hb-btn
                                icon
                                active-by-default
                                always-active
                                active-background-color="#637381"
                                color="#ffffff"
                                active-color="#ffffff"
                                hover-color="#ffffff"
                                hover-opacity
                                :disabled="!voicemailLoaded"
                                @click="voicemailPlayPause()"
                                :tooltip="!voicemailLoaded ? 'Please Wait - Voicemail is Loading' : 'Play/Pause Voicemail'"
                            >
                                mdi-play-pause
                            </hb-btn>

                            <div class="pl-1">
                                <div class="hb-communication-font-caption">Voicemail</div>
                                <div class="hb-communication-font-caption hb-communication-footer-element-wrapper">
                                    <div v-if="$slots.voicemailTime">
                                        <slot name="voicemailTime"></slot>
                                    </div>
                                    <div v-else>
                                        {{ voicemailCurrentTime }}<span class="hb-communication-text-night-light">/{{ voicemailTotalTime }}</span>
                                    </div>
                                </div>
                            </div>

                            <audio
                                style="display:none;"
                                ref="voicemail"
                                controls
                                :src="voicemail"
                                preload
                                @canplay="voicemailLoaded = true"
                                @ended="voicemailPaused = true"
                                @loadedmetadata="updateTotalTime('voicemail')"
                                @timeupdate="onTimeUpdate('voicemail')"
                            ></audio>
                        </div>

                        <div v-else-if="type == 'document'" :style="!hideNotesButton || !hideViewPrintButton && systemGenerated ? 'padding:2px 0;' : ''">
                            <div v-if="status" class="pr-2" style="position:relative;top:-1px;display:inline-block">
                                <HbStatusGeneral :status="status" :type="statusType" @emitColor="communicationInfoColor = $event" />
                            </div>
                            <div v-if="statusTime" class="pr-2 hb-communication-text-night-light hb-communication-font-caption" style="display:inline-block">{{ statusTime }}</div>
                            <div v-if="trackingId" class="hb-communication-font-caption" style="display:inline-block">
                                <span class="hb-communication-text-night font-weight-medium">Tracking ID: </span>
                                <hb-link v-if="trackingIdClickable" color="secondary" @click="$emit('tracking-id-clicked', payload)">{{ trackingId }}</hb-link>
                                <span v-else>{{ trackingId }}</span>
                            </div>
                        </div>

                    </div>

                </v-col>

                <v-col cols="6" no-gutters class="py-2">

                    <div v-if="$slots.rightActions" class="hb-communication-text-night-light">
                        <slot name="rightActions"></slot>
                    </div>
                    <div v-else>
                        <div v-if="type == 'email out' || type == 'text out' || type == 'system email' || type == 'system text'" class="d-flex align-center float-right">
                            <div v-if="systemGenerated" class="pl-2 hb-communication-font-caption hb-communication-text-night" align="right">
                                System Generated
                            </div>
                            <div v-else-if="adminName" class="pl-2 hb-communication-font-caption hb-communication-text-night" align="right">
                                Sent by: {{ adminName }}
                            </div>
                            <hb-btn
                                v-if="type == 'email out' && !hideResendButton"
                                small color="secondary"
                                @click="$emit('resend-email', payload)"
                                class="pl-2"
                            >
                                Resend
                            </hb-btn>
                            <hb-btn
                                v-if="type == 'text out' && !hideResendButton"
                                small
                                color="secondary"
                                @click="$emit('resend-text', payload)"
                                class="pl-2"
                            >
                                Resend
                            </hb-btn>
                        </div>
                        <div v-else-if="type == 'call in' || type == 'call out'" class="d-flex align-center float-right">
                            <div v-if="adminName" class="pl-2 hb-communication-font-caption hb-communication-text-night" align="right">
                                Manager: {{ adminName }}
                            </div>
                            <hb-btn
                                v-if="!hideCallBackButton"
                                icon
                                active-by-default
                                always-active
                                active-background-color="#02AD0F"
                                color="#ffffff"
                                active-color="#ffffff"
                                hover-color="#ffffff"
                                hover-opacity
                                @click="$emit('call-back', payload)"
                                tooltip="Call Back"
                                class="pl-2"
                            >
                                mdi-phone-forward
                            </hb-btn>
                        </div>
                        <div v-if="type == 'email in' && !hideReplyButton" class="d-flex align-center float-right">
                            <hb-btn small color="secondary" @click="$emit('reply-email', payload)">Reply</hb-btn>
                        </div>
                        <div v-if="type == 'text in' && !hideReplyButton" class="d-flex align-center float-right">
                            <hb-btn small color="secondary" @click="$emit('reply-text', payload)">Reply</hb-btn>
                        </div>
                        <div v-if="type == 'document'" align="right">
                            <div v-if="systemGenerated" class="pl-2 hb-communication-font-caption hb-communication-text-night hb-communication-display-inline-block" :class="!hideNotesButton && !hideViewPrintButton ? 'pb-2' : 'pb-1'" align="right" style="position:relative;top:1px;">
                                System Generated
                            </div>

                            <hb-btn v-if="hasNotes && !hideNotesButton" class="pl-2 hb-communication-display-inline-block" :class="{ 'pb-2' : minWidthFooterThreshold }" small color="secondary" @click="$emit('view-notes', payload)">View Notes</hb-btn>
                            <hb-btn v-else-if="!hideNotesButton" class="pl-2 hb-communication-display-inline-block" :class="{ 'pb-2' : minWidthFooterThreshold }" small color="secondary" @click="$emit('add-notes', payload)">Add Notes</hb-btn>
                            <hb-btn v-if="!hideViewPrintButton" class="pl-2 hb-communication-display-inline-block" small color="secondary" @click="$emit('view-print', payload)">View / Print</hb-btn>
                        </div>
                        <div v-else-if="(type == 'voicemail' || type == 'missed') && !hideCallBackButton" class="d-flex align-center float-right">
                            <hb-btn
                                icon
                                active-by-default
                                always-active
                                active-background-color="#02AD0F"
                                color="#ffffff"
                                active-color="#ffffff"
                                hover-color="#ffffff"
                                hover-opacity
                                @click="$emit('call-back', payload)"
                                tooltip="Call Back"
                            >
                                mdi-phone-forward
                            </hb-btn>
                        </div>
                        <div v-else-if="type == 'note'">
                            <div v-if="systemGenerated" class="pl-2 hb-communication-font-caption hb-communication-text-night" align="right">
                                System Generated
                            </div>
                            <div v-else-if="adminName" class="pl-2 hb-communication-font-caption hb-communication-text-night" align="right">
                                Created by: {{ adminName }}
                            </div>
                        </div>
                    </div>

                </v-col>
            </v-row>

        </div>

        <div
            v-if="!preview && errorDescription && !hideErrorDescription && status && (status == 'Bounced' || status == 'Error' || status == 'Failed' || status == 'Resolved' || statusType == 'error' || statusType == 'guidance') && type == 'document'"
            class="hb-communication-error-wrapper hb-communication-contact-info-border-radius px-3 py-2"
            :class="communicationInfoBackgroundClass"
        >

            <v-row no-gutters class="hb-communication-font-caption">
                <v-col no-gutters>
                    <span class="font-weight-medium text-capitalize">{{ status }} Description:</span>
                    <span class="pl-1 hb-communication-text-night-light">{{ errorDescription }}</span>
                </v-col>
            </v-row>

        </div>

        <div v-if="preview && active" class="hb-communication-active-preview-vector">
            &nbsp;
        </div>

        
    </div>
    
</template>

<script type="text/babel">

import HbIcon from './HbIcon.vue';
import HbStatus from './HbStatus.vue';
import HbStatusGeneral from './HbStatusGeneral.vue';
import HbSpaceIcon from './HbSpaceIcon.vue';

export default {
    name:'HbCommunication',
    data(){
        return {
            expandable: false,
            contactInfoBackgroundColor: '#F4F6F8',
            communicationInfoColor: '#F4F6F8',
            voicemailPaused: true,
            voicemailCurrentTime: '0:00',
            voicemailTotalTime: '0:00',
            voicemailLoaded: false,
            playbackPaused: true,
            playbackCurrentTime: '0:00',
            playbackTotalTime: '0:00',
            playbackLoaded: false,
            communicationWidth: 0,
            infoSlotHeight: 0
        }
    },
    components:{
        HbIcon,
        HbStatus,
        HbStatusGeneral,
        HbSpaceIcon
    },
    mounted(){
        this.$nextTick(()=>{
            this.calculateExpandability();
        });
        setTimeout(() => {
            this.calculateExpandability();
        }, 2000);
        setTimeout(() => {
            this.calculateExpandability();
        }, 5000);
    },
    computed: {
        setIcon() {
            var tempIcon = '';

            if(this.icon){
                tempIcon = this.icon;
            } else if(this.type == 'call in'){
                tempIcon = 'mdi-phone-incoming';
            } else if(this.type == 'call out'){
                tempIcon = 'mdi-phone-outgoing';
            } else if(this.type == 'missed'){
                tempIcon = 'mdi-phone-missed';
            } else if(this.type == 'reservation'){
                tempIcon = 'mdi-calendar-clock';
            } else if(this.type == 'voicemail'){
                tempIcon = 'mdi-voicemail';
            } else if(this.type == 'email in'){
                tempIcon = 'mdi-email-receive';
            } else if(this.type == 'email out'){
                tempIcon = 'mdi-email-send-outline';
            } else if(this.type == 'text in'){
                tempIcon = 'mdi-message-arrow-left';
            } else if(this.type == 'text out'){
                tempIcon = 'mdi-message-arrow-right-outline';
            } else if(this.type == 'system email'){
                tempIcon = 'mdi-clipboard-outline';
            } else if(this.type == 'system text'){
                tempIcon = 'mdi-clipboard-outline';
            } else if(this.type == 'document'){
                tempIcon = 'mdi-file';
            } else if(this.type == 'note'){
                tempIcon = 'mdi-content-paste';
            } else {
                tempIcon = ''
            }

            this.$emit('update:icon', tempIcon);
            return tempIcon;
        },
        setIconColor() {
            if(this.iconColor){
                return this.iconColor;
            } else {
                return '#101318';
            }
        },
        setTitleColor() {
            if(this.titleColor){
                return this.titleColor;
            } else {
                return '#101318';
            }
        },
        setTitle() {
            var tempTitle = '';

            if(this.title){
                tempTitle = this.title;
            } else if(this.type == 'call in'){
                tempTitle = 'Call (In)';
            } else if(this.type == 'call out'){
                tempTitle = 'Call (Out)';
            } else if(this.type == 'missed'){
                tempTitle = 'Missed Call';
            } else if(this.type == 'reservation'){
                tempTitle = 'Reservation';
            } else if(this.type == 'voicemail'){
                tempTitle = 'Voicemail';
            } else if(this.type == 'email in'){
                tempTitle = 'Email (In)';
            } else if(this.type == 'email out'){
                tempTitle = 'Email (Out)';
            } else if(this.type == 'text in'){
                tempTitle = 'Text (In)';
            } else if(this.type == 'text out'){
                tempTitle = 'Text (Out)';
            } else if(this.type == 'system email'){
                tempTitle = 'System Email';
            } else if(this.type == 'system text'){
                tempTitle = 'System Text';
            } else if(this.type == 'document'){
                tempTitle = 'Document';
            } else if(this.type == 'note'){
                tempTitle = 'Note';
            } else {
                tempTitle = ''
            }

            this.$emit('update:title', tempTitle);
            return tempTitle;
        },
        contactInfoBackgroundClass() {
            if(this.contactInfoBackgroundColor == '#02AD0F'){
                return 'hb-communication-background-success-shade';
            } else if(this.contactInfoBackgroundColor == '#FFD600'){
                return 'hb-communication-background-caution-shade';
            } else if(this.contactInfoBackgroundColor == '#FB4C4C'){
                return 'hb-communication-background-error-shade';
            } else if(this.contactInfoBackgroundColor == '#3D8FFF') {
                return 'hb-communication-background-guidance-shade';
            } else if(this.contactInfoBackgroundColor == '#919EAB') {
                return 'hb-communication-background-cloud-light';
            } else {
                return 'hb-communication-background-cloud-lighter';
            }
        },
        communicationInfoBackgroundClass() {
            if(this.communicationInfoColor == '#02AD0F'){
                return 'hb-communication-background-success-shade';
            } else if(this.communicationInfoColor == '#FFD600'){
                return 'hb-communication-background-caution-shade';
            } else if(this.communicationInfoColor == '#FB4C4C'){
                return 'hb-communication-background-error-shade';
            } else if(this.communicationInfoColor == '#3D8FFF') {
                return 'hb-communication-background-guidance-shade';
            } else if(this.communicationInfoColor == '#637381') {
                return 'hb-communication-background-cloud-light';
            } else {
                return 'hb-communication-background-cloud-lighter';
            }
        },
        voicemail(){
            if(this.voicemailUrl){
                return this.voicemailUrl;
            } else {
                return '';
            }
        },
        playback(){
            if(this.playbackUrl){
                return this.playbackUrl;
            } else {
                return '';
            }
        },
        payload() {
            let data = {
                id: this.id,
                contact_id: this.contactId,
                contact_name: this.contactName,
                contact_email: this.contactEmail,
                contact_phone: this.contactPhone,
                alternate_contact: this.alternateContact,
                alternate_contact_id: this.alternateContactId,
                alternate_contact_type: this.alternateContactType,
                alternate_contact_name: this.alternateContactName,
                alternate_contact_email: this.alternateContactEmail,
                alternate_contact_phone: this.alternateContactPhone,
                space_id: this.spaceId,
                reservation_id: this.reservationId,
                document_id: this.documentId,
                tracking_id: this.trackingId,
                tracking_url: this.trackingUrl
            }
            return data;
        },
        infoLineEntries() {
            var counter = 0;

            if(this.alternateContact){
                counter++
            }
            if(this.documentName && this.type == 'document'){
                counter++
            }
            if(this.deliveredBy && (this.type == 'system email' || this.type == 'document')){
                counter++
            }
            if(this.deliveryMethod && (this.type == 'system email' || this.type == 'document')){
                counter++
            }
            if(this.property && (this.type == 'call in' || this.type == 'call out' || this.type == 'voicemail' || this.type == 'missed' || this.type == 'reservation')){
                counter++
            }
            if((this.space || (this.subject && (this.type == 'system email' || this.type == 'email in' || this.type == 'email out'))) && !this.preview){
                counter++
            }
            if(this.subject && (this.type == 'system email' || this.type == 'email in' || this.type == 'email out') && this.preview){
                counter++
            }
            if(this.source && (this.type == 'call in' || this.type == 'call out' || this.type == 'voicemail' || this.type == 'missed' || this.type == 'reservation')){
                counter++
            }
            if(this.notes && (this.type == 'call in' || this.type == 'call out')){
                counter++
            }
            if(this.$slots.default){
                counter++
            }
            return counter;
        },
        expandIconClass(){
            if(this.infoLineEntries === 2){
                return 'hb-communication-expand-icon-class-variant-one';
            } else if(this.infoLineEntries >= 3){
                return 'hb-communication-expand-icon-class-variant-two';
            } else {
                return 'hb-communication-expand-icon-class-default';
            }
        },
        minWidthFooterThreshold(){
            if(this.communicationWidth < 641){
                return true;
            } else {
                return false;
            }
        }
    },  
    methods: {
        voicemailPlayPause() {
            this.updateTotalTime('voicemail');
            if(this.$refs.voicemail){
                if(this.voicemailPaused){
                    this.$refs.voicemail.play();
                    this.voicemailPaused = false;
                    this.$emit('voicemail-played');
                } else {
                    this.$refs.voicemail.pause();
                    this.voicemailPaused = true;
                    this.$emit('voicemail-paused');
                }
            }
        },
        playbackPlayPause() {
            this.updateTotalTime('playback');
            if(this.$refs.playback){
                if(this.playbackPaused){
                    this.$refs.playback.play();
                    this.playbackPaused = false;
                    this.$emit('playback-played');
                } else {
                    this.$refs.playback.pause();
                    this.playbackPaused = true;
                    this.$emit('playback-paused');
                }
            }
        },
        onTimeUpdate (type) {
            if(this.$refs.voicemail && type == 'voicemail'){
                this.voicemailCurrentTime = this.convertTime(this.$refs.voicemail.currentTime);
            } else if(this.$refs.playback && type == 'playback'){
                this.playbackCurrentTime = this.convertTime(this.$refs.playback.currentTime);
            }
        },
        updateTotalTime(type){
            if(this.$refs.voicemail && type == 'voicemail'){
                this.voicemailTotalTime = this.convertTime(this.$refs.voicemail.duration);
            } else if(this.$refs.playback && type == 'playback'){
                this.playbackTotalTime = this.convertTime(this.$refs.playback.duration);
            }
        },
        convertTime(time) {    
            var mins = Math.floor(time / 60);

            var secs = Math.floor(time % 60);
                if (secs < 10) {
            secs = '0' + String(secs);
            }

            return mins + ':' + secs;
        },
        toggleExpanded(){
            if(this.expanded){
                this.$emit('update:expanded', false);
            } else {
                this.$emit('update:expanded', true);
            }
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        calculateExpandability() {
            var mainRef = this.$refs.hbCommunicationRef;
            var infoSlotRef = this.$refs.hbCommunicationInfoSlotRef;

            if(mainRef){
                this.communicationWidth = this.$refs.hbCommunicationRef.offsetWidth;
            }
            if(this.infoLineEntries > 3 || this.expanded){
                this.expandable = true;
            } else {
                var infoWrapper = this.$refs.hbCommunicationInfoRef;
                var isOverflowing = false;

                if(infoSlotRef){
                    this.infoSlotHeight = infoSlotRef.offsetHeight;
                    if(this.infoLineEntries === 1 && this.infoSlotHeight > 48){
                        isOverflowing = true;
                    } else if(this.infoLineEntries === 2 && this.infoSlotHeight > 32){
                        isOverflowing = true;
                    } else if(this.infoLineEntries === 3 && this.infoSlotHeight > 16){
                        isOverflowing = true;
                    }
                }

                if(infoWrapper){
                    var elementOne = infoWrapper.children[0];
                    var elementTwo = infoWrapper.children[1];
                    var elementThree = infoWrapper.children[2];
                    var infoWrapperWidth = infoWrapper.offsetWidth;

                    if(elementOne){
                        if(elementOne.offsetHeight > 0 && elementOne.scrollWidth > infoWrapperWidth){
                            isOverflowing = true;
                        }
                    }
                    if(elementTwo){
                        if(elementTwo.offsetHeight > 0 && elementTwo.scrollWidth > infoWrapperWidth){
                            isOverflowing = true;
                        }
                    }
                    if(elementThree){
                        if(elementThree.offsetHeight > 0 && elementThree.scrollWidth > infoWrapperWidth){
                            isOverflowing = true;
                        }
                    }
                }

                if(isOverflowing){
                    this.expandable = true;
                } else {
                    this.expandable = false;
                }
            }
        }
    },
    props: {
        preview: {
            type: Boolean,
            default: false
        },
        elevation: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        expanded: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: undefined
        },
        systemGenerated: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: undefined
        },
        iconColor: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        titleColor: {
            type: String,
            default: undefined
        },
        id: {
            type: String,
            default: undefined
        },
        spaceId: {
            type: String,
            default: undefined
        },
        space: {
            type: String,
            default: undefined
        },
        spaceType: {
            type: String,
            default: undefined
        },
        reservationId: {
            type: String,
            default: undefined
        },
        subject: {
            type: String,
            default: undefined
        },
        property: {
            type: String,
            default: undefined
        },
        source: {
            type: String,
            default: undefined
        },
        notes: {
            type: String,
            default: undefined
        },
        deliveredBy: {
            type: String,
            default: undefined
        },
        deliveryMethod: {
            type: String,
            default: undefined
        },
        documentId: {
            type: String,
            default: undefined
        },
        documentName: {
            type: String,
            default: undefined
        },
        time: {
            type: String,
            default: undefined
        },
        hasImages: {
            type: Boolean,
            default: false
        },
        hasAttachments: {
            type: Boolean,
            default: false
        },
        hasNotes: {
            type: Boolean,
            default: false
        },
        unread: {
            type: Boolean,
            default: false
        },
        pinned: {
            type: Boolean,
            default: false
        },
        disablePinning: {
            type: Boolean,
            default: false
        },
        disableStatusPreview: {
            type: Boolean,
            default: false
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        trackingId: {
            type: String,
            default: undefined
        },
        trackingUrl: {
            type: String,
            default: undefined
        },
        trackingIdClickable: {
            type: Boolean,
            default: false
        },
        playbackUrl: {
            type: String,
            default: undefined
        },
        voicemailUrl: {
            type: String,
            default: undefined
        },
        hidePlayback: {
            type: Boolean,
            default: false
        },
        hideVoicemail: {
            type: Boolean,
            default: false
        },
        status: {
            type: String,
            default: undefined
        },
        statusType: {
            type: String,
            default: undefined
        },
        statusTime: {
            type: String,
            default: undefined
        },
        lastDownloadedTime: {
            type: String,
            default: undefined
        },
        adminName: {
            type: String,
            default: undefined
        },
        outgoing: {
            type: Boolean,
            default: false
        },
        contactId: {
            type: String,
            default: undefined
        },
        contactName: {
            type: String,
            default: undefined
        },
        contactEmail: {
            type: String,
            default: undefined
        },
        contactPhone: {
            type: String,
            default: undefined
        },
        contactStatus: {
            type: String,
            default: undefined
        },
        alternateContact: {
            type: Boolean,
            default: false
        },
        alternateContactId: {
            type: String,
            default: undefined
        },
        alternateContactType: {
            type: String,
            default: undefined
        },
        alternateContactName: {
            type: String,
            default: undefined
        },
        alternateContactEmail: {
            type: String,
            default: undefined
        },
        alternateContactPhone: {
            type: String,
            default: undefined
        },
        errorDescription: {
            type: String,
            default: undefined
        },
        hideErrorDescription: {
            type: Boolean,
            default: false
        },
        hideResendButton: {
            type: Boolean,
            default: false
        },
        hideReplyButton: {
            type: Boolean,
            default: false
        },
        hideCallBackButton: {
            type: Boolean,
            default: false
        },
        hideNotesButton: {
            type: Boolean,
            default: false
        },
        hideViewPrintButton: {
            type: Boolean,
            default: false
        },
        leftActionsSlotAttachments: {
            type: Boolean,
            default: true
        },
        noteCategory: {
            type: String,
            default: undefined
        },
        contactIsBusiness: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        preview(){
            if(this.preview && this.expanded){
                this.$nextTick(()=>{
                    this.$emit('update:expanded', false);
                });
            }
            if(!this.preview){
                this.$nextTick(()=>{
                    this.calculateExpandability();
                });
            }
            if(this.$refs.voicemail){
                this.voicemailLoaded = false;
                this.$refs.voicemail.pause();
                this.voicemailPaused = true;
                this.$refs.voicemail.currentTime = 0;
                this.voicemailCurrentTime = '0:00';
                this.voicemailTotalTime = '0:00';
            } else if(this.$refs.playback){
                this.playbackLoaded = false;
                this.$refs.playback.pause();
                this.playbackPaused = true;
                this.$refs.playback.currentTime = 0;
                this.playbackCurrentTime = '0:00';
                this.playbackTotalTime = '0:00';
            }
        },
        active(){
            if(this.$refs.voicemail){
                this.voicemailLoaded = false;
                this.$refs.voicemail.pause();
                this.voicemailPaused = true;
                this.$refs.voicemail.currentTime = 0;
                this.voicemailCurrentTime = '0:00';
                this.voicemailTotalTime = '0:00';
            } else if(this.$refs.playback){
                this.playbackLoaded = false;
                this.$refs.playback.pause();
                this.playbackPaused = true;
                this.$refs.playback.currentTime = 0;
                this.playbackCurrentTime = '0:00';
                this.playbackTotalTime = '0:00';
            }
        },
        voicemailUrl(){
            if(this.$refs.voicemail){
                this.voicemailLoaded = false;
                this.$refs.voicemail.pause();
                this.voicemailPaused = true;
                this.$refs.voicemail.currentTime = 0;
                this.voicemailCurrentTime = '0:00';
                this.voicemailTotalTime = '0:00';
                this.$refs.voicemail.setAttribute('src', this.voicemailUrl)
                this.$refs.voicemail.load();
            }
        },
        playbackUrl(){
            if(this.$refs.playback){
                this.playbackLoaded = false;
                this.$refs.playback.pause();
                this.playbackPaused = true;
                this.$refs.playback.currentTime = 0;
                this.playbackCurrentTime = '0:00';
                this.playbackTotalTime = '0:00';
                this.$refs.playback.setAttribute('src', this.playbackUrl)
                this.$refs.playback.load();
            }
        },
        alternateContact(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        documentName(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        deliveredBy(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        deliveryMethod(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        property(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        space(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        subject(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        source(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        notes(){
            this.$nextTick(()=>{
                this.calculateExpandability();
            });
        },
        communicationWidth(){
            this.$emit('width-change', this.communicationWidth);
        },
        expandable(){
            if(!this.expanded && (this.type === 'text in' || this.type === 'text out' || this.type === 'note')){
                this.$emit('update:expanded', true);
            }
        }
    }
}
</script>

<style scoped>

.hb-communication-wrapper {
    background: #ffffff;
    border: 1px solid #C4CDD5;
    border-radius: 4px;
    width: 100%;
    color: #101318;
    font-size: 14px;
    position: relative;
}

.hb-communication-background {
    background: #ffffff;
    position: relative;
    z-index: 2;
}

.hb-communication-wrapper-active {
    border: 2px solid #00848E;
}

.hb-communication-wrapper-preview {
    max-width: 328px;
    cursor: pointer;
}

.hb-communication-wrapper-elevation {
    box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-communication-content-wrapper {
    min-height: 72px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.hb-communication-content-condensed-preview-set-min-height-less-than-three {
    max-height: 90px;
}

.hb-communication-content-condensed-preview-set-min-height-three-plus {
    max-height: 90px;
}

.hb-communication-content-condensed-not-preview-set-min-height {
    min-height: 78px;
    max-height: 92px;
}

.hb-communication-content-condensed-not-preview-set-min-height-three-plus {
    max-height: 112px;
}

.hb-communication-overflow-handler, .hb-communication-overflow-handler .hb-communication-header *, .hb-communication-info-wrapper-overflow-handler > div, .hb-communication-info-wrapper-overflow-handler .hb-communication-default-slot-preview-overflow * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-communication-default-slot-overflow-handler-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.hb-communication-default-slot-overflow-handler-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.hb-communication-font-caption {
    font-size: 12px;
    line-height: 16px;
}

.hb-communication-text-night {
    color: #101318;
}

.hb-communication-text-night-light {
    color: #637381;
}

.hb-communication-unread-dot {
    background-color: #f26500;
    height: 8px;
    min-width: 8px;
    border-radius: 4px;
    position: relative;
    top: 2px;
}

.hb-communication-contact-info-wrapper {
    min-height: 36px;
}

.hb-communication-contact-info-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.hb-communication-background-cloud-lighter {
    background-color: #F9FAFB;
}

.hb-communication-background-cloud-light {
    background-color: #F4F6F8;
}

.hb-communication-background-error-shade {
    background-color: #F8E5E1;
}

.hb-communication-background-caution-shade {
    background-color: #FFF9DA;
}

.hb-communication-background-success-shade {
    background-color: #E1FAE3;
}

.hb-communication-background-guidance-shade {
    background-color: #E7F1FF;
}

.hb-communication-actions-wrapper {
    min-height: 36px;
    background: #F4F6F8;
}

.hb-communication-active-preview-vector {
    position: absolute;
    left: 100%;
    bottom: 45%;
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #00848E;
    transform: scaleY(1.5);
    z-index: 1;
}

.hb-communication-footer-element-wrapper {
    margin-top: 3px;
}

.hb-communication-info-not-preview > div {
    margin-top: 3px;
}

.hb-communication-alternate-contact-dash-divider {
    margin-left: 5px;
    margin-right: 2px;
}

.hb-communication-info-condensed-wrapper > div:nth-child(n+4) {
    display: none;
}

.hb-communication-expand-icon {
    position:absolute;
    right:-5px;
}

.hb-communication-expand-icon-class-variant-default {
    top: 22px;
}

.hb-communication-expand-icon-class-variant-one {
    top: 24px;
}

.hb-communication-expand-icon-class-variant-two {
    top: 43px;
}

.hb-communication-display-inline-block {
    display: inline-block;
}

</style>