<template>
  <div>
    <hb-modal
      @close="$emit('close')"
      size="large"
      :title="title + 'Payment'"
      v-model="dialog"
      :footerCancelOption="false"
      show-help-link
    >
      <template v-slot:content>
        <hb-form :label="title + 'Payment Type'" required full>
         <v-col cols="12">
          <hb-select
            v-model="reversal.type"
            :items="Object.values(getReversalOptions)"
            v-validate.disable="'required|max:45'"
            id="reversalType"
            name="reversalType"
            data-vv-scope="reversal"
            data-vv-as="Reverse Payment Type"
            :error="errors.has('reversal.reversalType')"
            :placeholder="'Select ' + title + 'Payment Type'"
          ></hb-select>
         </v-col>



          <div class="panel-header" v-if="reversal.type === 'directrefund' && paymentMethod === 'directdebit'"> 
            <img class="cc-icon" :src="'/img/cc_icons/64/directdebit.png'" />
            <span class="hb-font-body mx-1"> Account ending in {{ payment.PaymentMethod.card_end }}</span>
          </div>

          <v-col cols="6" v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' )" required full>
              <v-text-field
                v-model="reversal.directrefund_account_name"
                v-validate.disable="'required|max:32'"
                data-vv-as="Account Name"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_account_name')"
                label="Account Name*"
                id="directrefund_account_name"
                name="directrefund_account_name"
                >
              </v-text-field>
            </v-col>

            <v-col cols="6" v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' )" required full>
              <v-text-field
                v-model="reversal.directrefund_bsb_number"
                v-validate.disable="'required|numeric|length:6'"
                maxlength="6"
                data-vv-as="BSB Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_bsb_number')"
                label="BSB Number*"
                id="directrefund_bsb_number"
                name="directrefund_bsb_number"
                >
              </v-text-field>
            </v-col>

            <v-col cols="6" v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' )" required full>
              <v-text-field
                v-model="reversal.directrefund_account_number"
                v-validate.disable="'required|numeric|min:4|max:9|'"
                minlength='4'
                maxlength="9"
                data-vv-as="Account Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_account_number')"
                label="Account Number*"
                id="directrefund_account_number"
                name="directrefund_account_number"
              >
              </v-text-field>
            </v-col>

          <div class="mt-3 mb-1 hb-text-light">
            <span v-if="reversal.type === REVERSALS.card.CHARGEBACK.value || reversal.type === REVERSALS.card.OFFLINE.value">
              Take this action to remove the payment line from the
              tenant’s ledger and issue a chargeback fee. You will also change
              the status of the invoice to unpaid.
            </span>
            <span v-if="reversal.type === REVERSALS.check.NSF.value">
              Take this action to remove the payment line from the tenant’s
              ledger and issue them an NSF fee. You will also change the status
              of the invoice to unpaid.
            </span>
            <span v-if="reversal.type === REVERSALS.ach.ACH.value">
              Take this action to remove the payment line item from the
              tenant’s ledger and issue an ACH reversal fee. This action does
              not credit the tenant’s credit card. This action will change the
              status of the tenant’s invoice to unpaid.
            </span>
            <span v-if="reversal.type === 'void'">
              Take this action to remove the payment line from the tenant’s ledger. You will also change the status of the invoice to unpaid.
            </span>
          </div>
        </hb-form>
        
        <hb-form class="refund-reversal" label="Refund Amount" v-if="showRefund" full>
          <v-col >
            <hb-notification
            v-if="reversal.type === 'refund' && paymentMethod === 'eftpos'" required full
              v-model="notification"
              title=""
              type="info"
              not-dismissable
            >EFTPOS refund total must be less then $ 100. Otherwise, Direct Refund will be used.</hb-notification>
            <hb-notification
            v-if="reversal.warning" required full
              v-model="notification"
              title=""
              @close="reversal.warning=false"
              type="caution"
            > Refund amount crosses $100- Direct Refund will be chosen by default.</hb-notification>
            <hb-notification
            v-if=" total_eftpos_amount > 300" required full
              v-model="notification"
              title=""
              @close="total_eftpos_amount=false"
              type="caution"
            >  Daily EFTPOS Refund Limit is crossing $300- Direct Refund will be selected by default.</hb-notification>
          </v-col>
          <v-col>
            <refund
            v-if="showRefund"
            :payments="payments"
            :payment="payment"
          />
          </v-col>
        </hb-form>

        <hb-form label="Refund Transaction ID" 
          v-if="reversal.type === 'refund' && paymentMethod === 'eftpos'" required full>
          <v-col>
            <hb-textarea
            v-model="reversal.refund_trans_id"
            v-validate.disable="'required|max:1000'"
            id="refund_trans_id"
            name="refund_trans_id"
            data-vv-scope="reversal"
            data-vv-as="Refund Transaction ID"
            :error="errors.has('reversal.refund_trans_id')"
            placeholder="Enter Refund Transaction ID"
          ></hb-textarea>
          </v-col>
        </hb-form>

        <hb-form :label="'Reason for ' + title" required full last>
          <v-col cols="12">
            <hb-textarea
            v-model="reversal.reason"
            v-validate.disable="'required|max:1000'"
            id="reason"
            name="reason"
            data-vv-scope="reversal"
            data-vv-as="Reason for Reversal"
            :error="errors.has('reversal.reason')"
            :placeholder="'Enter Reason for ' + title"
          ></hb-textarea>
          </v-col>
        </hb-form>
      </template>
      <template v-slot:actions>
        <hb-btn v-if="paymentMethod !== 'eftpos'" color="primary" @click="reversePayment">{{title}} {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
        <hb-btn v-else-if="paymentMethod === 'eftpos' && reversal.type === 'refund'" color="primary" @click="reversePayment">{{title}} {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
        <hb-btn v-else-if="paymentMethod === 'eftpos' && reversal.type === 'void'" color="primary" @click="voidPayment">Void {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
        <hb-btn v-else color="primary" @click="reversePayment">{{title}} {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import REVERSAL from "@/constants/reversal.js";
import { mapMutations, mapGetters } from "vuex";
import { EventBus } from "../../EventBus.js";
import api from '../../assets/api.js';
import Refund from "./Refund.vue";
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "Reversal",
  mixins: [notificationMixin],
  data() {
    return {
      total_eftpos_amount:"",
      reversal: {
        warning:"",
        type: "",
        reason: "",
        refund_trans_id: "",
        directrefund_account_number: "",
        directrefund_bsb_number: "",
        directrefund_account_name: ""
      },
      showRefund: false,
    };
  },

  created() {
    this.REVERSALS = REVERSAL.REVERSALS;
    this.REFUND = REVERSAL.REFUND;
    EventBus.$on("closeReversalModal", this.closeReversalModal);
  },

  methods: {
    ...mapMutations({
      setReversal: "paymentsStore/setReversal",
    }),

    closeReversalModal() {
      this.dialog = false;
    },

    async reversePayment() {
      const invoices_to_reverse = this.reversal_meta?.invoices || [];
      const methods_with_partial_payments = this.getMethodsWithPartialPayments();
      let reversal_type = this.reversal?.type.toUpperCase() || "";
      if(this.reversal?.type === 'credit') reversal_type = 'REVERSE';
    
      let totalAmount = 0;
      invoices_to_reverse.forEach(invoices_to_reverse => {
        totalAmount += invoices_to_reverse.amount;
      });

      if(this.reversal.type === 'refund' && this.paymentMethod === 'eftpos' && totalAmount >= 100){
        this.reversal.type = "directrefund"
         this.reversal.warning = true
        return;
      }

      if(this.reversal.type === 'refund' && this.paymentMethod === 'eftpos' && totalAmount + this.total_eftpos_amount > 300){
        this.reversal.type = "directrefund"
        this.total_eftpos_amount = totalAmount + this.total_eftpos_amount 
        return;
      }


      if( reversal_type != '' && methods_with_partial_payments.indexOf(reversal_type) != -1 && invoices_to_reverse?.length == 0){
        this.showMessageNotification({ type: 'error', description: 'Please select the invoice(s) to refund.'});
        return;
      }

      const valid = await this.$validator.validateAll('reversal');
      if (!valid) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      }

      for(let i = 0; i < invoices_to_reverse.length; i++) {
        if( invoices_to_reverse[i].amount > invoices_to_reverse[i].invoice_amount ){
          this.showMessageNotification({ type: 'error', description: 'You are trying to refund more than the payment applied.'});
          return;
        }
      }

      this.setReversal({
        property: "reversal_type",
        propertyValue: this.reversal.type,
      });

      this.setReversal({
        property: "reason",
        propertyValue: this.reversal.reason,
      });
      this.setReversal({
        property: "refund_trans_id",
        propertyValue: this.reversal.refund_trans_id,
      });
      if(this.reversal.type === 'directrefund'){
      this.setReversal({
        property: "account_name",
        propertyValue: this.reversal.directrefund_account_name,
      });
      this.setReversal({
        property: "bsb_number",
        propertyValue: this.reversal.directrefund_bsb_number,
      });
      this.setReversal({
        property: "account_number",
        propertyValue: this.reversal.directrefund_account_number,
      });
      this.setReversal({
        property: "payment_method",
        propertyValue: this.paymentMethod
      });
    }

      this.dialog = false;

      let reversalOptions = this.REVERSALS[this.paymentMethod];
      reversalOptions =  Object.values(reversalOptions)
        .find(x => x.value === this.reversal.type);

      const permission = reversalOptions?.permission

      if(permission && !this.hasPermission(permission)){
        this.showMessageNotification({ description: 'You do not have permission to perform this action. Please contact your administrator.'});
      } else {
        EventBus.$emit("show_reversal_confirmation");
      }
    },

    async voidPayment(){
      console.log('Inside void');
      const valid = await this.$validator.validateAll('reversal');
      if (!valid) {
        return;
      }
      this.dialog = false;
      let reversalOptions = this.REVERSALS[this.paymentMethod];
      reversalOptions =  Object.values(reversalOptions)
        .find(x => x.value === this.reversal.type);

      const permission = reversalOptions?.permission

      if(permission && !this.hasPermission(permission)){
        this.showMessageNotification({ description: 'You do not have permission to perform this action. Please contact your administrator.'});
      } else {
        EventBus.$emit("show_void_confirmation");
      }
    },

    getPartialAllowedReversals() {
      let reversalOptions = this.REVERSALS[this.paymentMethod]; 
      const partialPaymentAllowedReversals = this.getMethodsWithPartialPayments();
      let filteredReversalOptions = {};
      for (const r of partialPaymentAllowedReversals) {
        filteredReversalOptions[r] = reversalOptions[r];
      }
      reversalOptions = filteredReversalOptions;
      return reversalOptions;
    },
    getMethodsWithPartialPayments(){
      let reversalOptions = this.REVERSALS[this.paymentMethod];      
      const methods = Object.keys(reversalOptions).filter(r => r === 'OFFLINE' || r === 'REVERSE' || r === 'REFUND' || r === 'CHARGEBACK' || r === 'DIRECTREFUND');
      return methods;
    },
    getFatzebraReversalOptions(reversalOptions, method){
      if(method == "cash"){
        return Object.values(reversalOptions).filter(item => item.value !== "refund");
      }else if(method == "directdebit"){
        return Object.values(reversalOptions).filter(item => item.value !== "void");
      }
      return reversalOptions
    },
    getCashReversalOptions(reversalOptions, method){
      if(method == "cash"){
        return Object.values(reversalOptions).filter(item => item.value !== "directrefund");
    }
  },
  getEftposData(){
    api.get(this, api.PAYMENTS +this.payment.id + '/getEftposPayments' ).then(r => {
      this.total_eftpos_amount = r[0].sum_amount                
                });
  }
  },

  computed: {
    ...mapGetters({
			reversal_meta: 'paymentsStore/getReversal'
		}),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    getReversalOptions() {
      let reversalOptions = this.REVERSALS[this.paymentMethod];
      
      if(this.isOnlyNSF)
        reversalOptions = {"NSF" : this.REVERSALS[this.paymentMethod]['NSF']};

      if(this.isPartialRefund) {
        reversalOptions = this.getPartialAllowedReversals();
      }
      if(!this.is_fatzebra_payment && this.paymentMethod == "cash") {
        reversalOptions = this.getCashReversalOptions(reversalOptions, this.paymentMethod);
      }
      if(this.is_fatzebra_payment){
        reversalOptions = this.getFatzebraReversalOptions(reversalOptions, this.paymentMethod)
      }
    

      return reversalOptions;
    },
    title(){
    if(this.is_fatzebra_payment){
      return  'Refund '
    }
      return this.paymentMethod == 'credit' ? 'Reverse Credit' : 'Reverse '
    }
  },

  components: {
    Refund,
  },

  destroyed(){
    EventBus.$off("closeReversalModal", this.closeReversalModal);
  },

  watch: {
    "reversal.type"(val) {
      if(val === this.REFUND.value || val === this.REVERSALS.card.OFFLINE.value || val === this.REVERSALS.credit.REVERSE.value || val === this.REVERSALS.eftpos.DIRECTREFUND.value || val === this.REVERSALS.cash.DIRECTREFUND.value || val === this.REVERSALS.directdebit.DIRECTREFUND.value ||  val === this.REVERSALS.card.CHARGEBACK.value) {
        this.showRefund = true;
      } else {
        this.showRefund = false;
      }
      if( this.reversal.type == "refund" && this.paymentMethod == "eftpos"){
        this.getEftposData()
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: String,
      required: true,
    },
    payments: {
      type: Array,
      default: [],
    },
    is_fatzebra_payment: {
      type: Boolean,
      required: false
    },
    refundOption: {
      type: Object,
      default: {},
    },
    payment: {
      type: Object,
      required: true,
    },
    isPartialRefund: {
      type: Boolean,
      required: false
    },
    isOnlyNSF: {
      type: Boolean,
      required: false
    }
  },
};
</script>

<style>
.refund-reversal .hb-aviary-form-padding-content {
	padding-right: 12px !important;
}
</style>
