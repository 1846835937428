<template>
    <hb-modal v-model="dialog" v-if="dialog" :confirmation="true" size="large" title="Reverse Move-out" show-help-link>
      <template v-slot:content>
        <div class="pa-6">
            You are about to Reverse the Move-Out.
            <ul class="mt-2 mb-2">
                <li>The invoices and delinquency of the lease will be restored to the state at the time of move-out.</li>
                <li>Any write-offs performed on the lease will be reverted.</li>
                <li>Any refunds processed will not be restored. Please collect the necessary payments from tenant.</li>
                <li>Gate Code cannot be restored. Please find the move-out note from communication center which contains the gate code and status at the time of move-out and restore the gate code and gate status.</li>
            </ul>
            Would you like to proceed?
         </div> 
      </template>
      <template v-slot:right-actions>
        <hb-btn
          color="primary"
          :loading="loader" 
          :disabled="loader" 
          @click="$emit('processReverseMoveOut')"
        >
          Yes
        </hb-btn>
      </template>
    </hb-modal>
  </template>
  
  <script type="text/babel">
    export default {
      name: "ReverseMoveOut",
      props: ['value','loader'],
      computed: {
        dialog: {
          get () {
            return this.value
          },
          set (value) {
            this.$emit('input', value)
          }
        }
      }
    }
  </script>