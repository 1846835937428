<template>
<!-- NOTE: IMPORTANT: -->
<!-- Any changes made to this file's html/vue needs to be made in 2 different places -->
<!-- because there is an "v-if" and "v-else" used here that both use the same content but it is a workaround -->
<!-- to handle both the legacy code implementation as well as the proper design system implementation -->
<!-- please do not miss doing this in both places, if you don't, then 1 place will not reflect the changes -->
<!-- NOTE: IMPORTANT: -->
  <div>
    <hb-modal
      v-if="modal"
      v-model="modalDialog"
      size="large"
      @click="$emit('close')"
      :title="auctionPayment ? 'Auction Payment' : 'Payment Details'"
      show-help-link
    >
      <template v-slot:content>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <status @resetStatus="errorClear('refund_payment')" v-if="errorHas('refund_payment')" :message="errorGet('refund_payment')" status="error"></status>
        <status @resetStatus="successClear('refund_payment')" v-if="successHas('refund_payment')" :message="successGet('refund_payment')" status="success"></status>
        <status @resetStatus="errorClear('download_receipt')" v-if="errorHas('download_receipt')" :message="errorGet('download_receipt')" status="error"></status>
        <status @resetStatus="successClear('download_receipt')" v-if="successHas('download_receipt')" :message="successGet('download_receipt')" status="success"></status>

        <div class="form-section" v-if="payment.AppliedPayments">
        <v-row v-if="auctionPayment" class="my-0 py-0 px-4 mt-2 mb-6" :class="{'pr-9' : showAside}">
          <hb-notification
              v-model="auctionPayment"
              v-if="auctionPayment"
              notDismissable
              type="success"
          >
              Auction Payment has been applied to outstanding invoices.
          </hb-notification>
        </v-row>
        <v-row v-if="is_inter_property_payment" class="my-0 py-0 px-4 mt-2 mb-6" :class="{'pr-9' : showAside}">
          <hb-notification
              v-model="is_inter_property_payment"
              v-if="is_inter_property_payment"
              notDismissable
              type="guidance"
          >
            This is an <span class="hb-font-body-medium">inter-property</span> payment.
          </hb-notification>
        </v-row>
          <v-row class="mx-0" v-if="!payment.is_settled && !paymentVoided" >
            <hb-notification type="caution" title="Warning:" :notDismissable="true" class="mb-4">
              <template > 
                This transaction is not eligible for a refund.
              </template>
            </hb-notification>
          </v-row>
          <v-row class="mx-0" v-if="paymentVoided">
            <hb-notification type="success" title="Success:" :notDismissable="true" class="mb-4">
              <template > 
                The payment has been voided successfully.
              </template>
            </hb-notification>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="6" class="pa-0 pr-4">
              <v-card class="hb-elevation-medium hb-border-default">
                <v-card-text class="pb-0" v-if="auctionPayment">
                  <span class="subtitle-1 font-weight-medium" style="line-height: 18px;">Auction Winner</span><br>
                  <span class="subtitle-1 font-weight-regular text-capitalize"> {{payment.Contact.first}} {{payment.Contact.last}} </span>
                </v-card-text>
                <v-card-title class="pa-4 pb-0" v-if="!auctionPayment"> 
                  <HbIcon v-if="isRentAsBusiness" color="#306FB6" :customSize="18" mdi-code="mdi-domain" />
                  <HbIcon v-else color="#306FB6" :customSize="18" mdi-code="mdi-account" />
                  <span class=" ml-1 subtitle-1 font-weight-regular text-capitalize hb-link-secondary"> {{payment.Contact.first}} {{payment.Contact.last}} </span>
                </v-card-title>
                <v-card-text class="px-4 pb-10" :class="auctionPayment ? 'pt-0' : ''">
                  <span class="subtitle-1" style="line-height: 18px;"> {{payment.Contact.email}}</span><br>
                  <span class="subtitle-1" style="line-height: 20px;" v-if="payment.Contact.Phones && payment.Contact.Phones.length > 0">{{payment.Contact.Phones[0].phone | formatPhone}} </span><br>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-card class="hb-elevation-medium hb-border-default">
                <v-card-title class="pa-4 pb-0">
                  <v-col class="d-flex align-center pa-0">
                  <span class="subtitle-1 font-weight-regular text-capitalize" style="color:#02AD0F;"> Amount Paid</span>
                  </v-col>
                  <v-col class="d-flex align-center justify-end pa-0">
                      <span class="subtitle-1 font-weight-regular hb-link" style="cursor: pointer;" @click="fetchPayment()"> Refresh</span>
                  </v-col>
                </v-card-title>
                <v-card-text class="px-4">
                  <div v-if="is_inter_property_payment">
                    <div class="subtitle-2" style="line-height: 18px;"> {{payment.InterPropertyPayment.sourcePayment.created | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma')}}</div>
                    <span class="font-weight-regular" style="line-height: 20px; font-size:24px;"> {{ payment.InterPropertyPayment.sourcePayment.amount | formatMoney}}</span>
                    <div class="subtitle-2" v-if="['card', 'ach'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0">{{payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_type | capitalize}}**** {{ payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_end}}</div>
                    <div class="subtitle-2" v-else-if="['directdebit'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0">Direct Debit**** {{ payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_end}}</div>
                    <div class="subtitle-2" v-else-if="['eftpos'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0">EFTPOS {{payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_type | capitalize}}</div>
                    <div class="subtitle-2" v-else-if="payment.InterPropertyPayment.sourcePayment.method == 'adjustment'">{{SUB_METHOD[payment.InterPropertyPayment.sourcePayment.sub_method] | capitalize}} Adjustment</div>
                    <div class="subtitle-2" v-else><span class="font-weight-medium">{{payment.InterPropertyPayment.sourcePayment.method | capitalize}} {{payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_type}}</span></div>
                    <div class="subtitle-2" v-if="['cash', 'check'].indexOf(payment.InterPropertyPayment.sourcePayment.method) < 0 && payment.InterPropertyPayment.sourcePayment.transaction_id">Transaction ID: {{payment.InterPropertyPayment.sourcePayment.transaction_id}}</div>
                    <div class="subtitle-2">Property: {{payment.InterPropertyPayment.sourcePayment.Property.number}} - {{payment.InterPropertyPayment.sourcePayment.Property.Address.city}} </div>
                    <div class="subtitle-2" v-if="['card', 'ach', 'directdebit'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0"><template v-if="payment.InterPropertyPayment.sourcePayment.method.toLowerCase()==='ach'"><span class="font-weight-medium">{{payment.InterPropertyPayment.sourcePayment.method.toUpperCase()}}</span></template><template v-if="payment.InterPropertyPayment.sourcePayment.method.toLowerCase()!=='ach'"><span class="font-weight-medium">{{payment.InterPropertyPayment.sourcePayment.method | capitalize}}</span></template>
                      <template v-if="payment.InterPropertyPayment.sourcePayment.payment_source"> : {{ payment.InterPropertyPayment.sourcePayment.payment_source }}</template></div>                      
                    <div class="subtitle-2" v-if="payment.AcceptedBy && payment.InterPropertyPayment.sourcePayment.source && payment.InterPropertyPayment.sourcePayment.source.toLowerCase()==='in-store'">By: {{payment.AcceptedBy.first}} {{payment.AcceptedBy.last}}</div>
                    <div class="subtitle-2">Amount paid to property: <span class="hb-font-body-medium">{{ getAppliedPaymentsToProperty | formatMoney }}</span></div>
                  </div>
                  <div v-else>
                    <div class="subtitle-2" style="line-height: 18px;"> {{payment.created | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma')}}</div>
                    <span class="font-weight-regular" style="line-height: 20px; font-size:24px;"> {{payment.amount | formatMoney}}</span><br>
                    <div class="subtitle-2" v-if="['card', 'ach'].indexOf(payment.method) >= 0">{{payment.PaymentMethod.card_type | capitalize}}**** {{ payment.PaymentMethod.card_end}}</div>
                    <div class="subtitle-2" v-else-if="['directdebit'].indexOf(payment.method) >= 0">Direct Debit**** {{ payment.PaymentMethod.card_end}}</div>
                    <div class="subtitle-2" v-else-if="['eftpos'].indexOf(payment.method) >= 0">EFTPOS {{payment.PaymentMethod.card_type | capitalize}}</div>
                    <div class="subtitle-2" v-else-if="payment.method == 'adjustment'">{{SUB_METHOD[payment.sub_method] | capitalize}} Adjustment</div>
                    <div class="subtitle-2" v-else><span class="font-weight-medium">{{payment.method | capitalize}} {{payment.PaymentMethod.card_type}}</span></div>
                    <div class="subtitle-2" v-if="['cash', 'check'].indexOf(payment.method) < 0 && payment.transaction_id">Transaction ID : {{payment.transaction_id}}</div>
                    <div class="subtitle-2" v-if="['card', 'ach', 'directdebit'].indexOf(payment.method) >= 0"><template v-if="payment.method.toLowerCase()==='ach'"><span class="font-weight-medium">{{payment.method.toUpperCase()}}</span></template><template v-if="payment.method.toLowerCase()!=='ach' &&payment.method.toLowerCase()!=='directdebit'"><span class="font-weight-medium">{{payment.method | capitalize}}</span></template><template v-if="payment.method.toLowerCase()=='directdebit'"><span class="font-weight-medium">Direct Debit</span></template>
                      <template v-if="payment.payment_source"> : {{ payment.payment_source }}</template></div>
                    <div class="subtitle-2" v-if="payment.AcceptedBy && payment.source && payment.source.toLowerCase()==='in-store'">By : {{payment.AcceptedBy.first}} {{payment.AcceptedBy.last}}</div>
                  </div>
                  
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div class="font-weight-medium text-black mt-4" v-if="!auctionPayment">
            Payment is applied to the invoices listed:
          </div>
          <!-- <div>
            To refund an invoice select the checkbox.
          </div> -->
          <div class="font-weight-medium text-black mt-4" v-if="auctionPayment">Payment is applied to the items below</div>
          <v-row class="mx-0 mt-4">
            <v-col class="pa-0">
              <v-card class="hb-border-default" elevation="0">
                <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell" style="flex-wrap: unset;">
                  <!-- <v-col cols="1" class="d-flex align-center">
                    <span class="ml-3 subtitle-2 font-weight-medium"></span>
                  </v-col> -->
                  <v-col cols="3" class="d-flex align-center justify-start pr-1" v-if="is_inter_property_payment">
                    <span class="subtitle-2 font-weight-medium">Property ID</span>
                  </v-col>
                  <v-col cols="1" class="d-flex align-center justify-start px-0" v-if="is_inter_property_payment">
                    <span class="subtitle-2 font-weight-medium">Space #</span>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center justify-start pr-0 pl-4" v-if="is_inter_property_payment">
                    <span class="subtitle-2 font-weight-medium">Invoice Date</span>
                  </v-col>
                  <v-col :cols="is_inter_property_payment ?'2':'5'" :class="`d-flex align-center justify-start ${is_inter_property_payment ? 'px-0':'pl-4'}`">
                    <span class="ml-3 subtitle-2 font-weight-medium" v-if="auctionPayment">Description</span>
                    <span class="ml-3 subtitle-2 font-weight-medium" v-else>Invoice Number</span>
                  </v-col>
                  <v-col :cols="is_inter_property_payment ?'2':'3'" class="d-flex align-center justify-end">
                    <span class="subtitle-2 font-weight-medium" v-if="!auctionPayment">Invoice Total</span>
                  </v-col>
                  <v-col :cols="is_inter_property_payment ?'2':'3'" :class="`d-flex align-center justify-end pl-0 ${is_inter_property_payment?'':'ml-8'}`">
                      <span class="subtitle-2 font-weight-medium" v-if="auctionPayment">Amount Paid</span>
                  
                      <span class="subtitle-2 font-weight-medium" v-else-if="is_fatzebra_payment">Applied</span>

                      <span class="subtitle-2 font-weight-medium" v-else>Paid</span>
                  </v-col>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0 ma-0 hb-default-font-size">
                  <div v-for="p in appliedPayments" :key="p.id">
                    <v-row :class="`d-flex pa-0 mx-0 ${ is_inter_property_payment && p.Invoice.property_id !== payment.property_id? 'disabled-row': ''}`" v-if="p.Invoice">
                      <!-- <v-col cols="1" class="d-flex align-center pt-1">
                        <v-checkbox hide-details dense class="mt-0" v-model="selectedInvoices" :value="p" column :disabled="!payment.is_settled"></v-checkbox>
                      </v-col> -->
                      <v-col cols="3" class="d-flex align-center justify-start pr-1" v-if="is_inter_property_payment">
                        <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.Invoice && `${p.Invoice.property_info.number} - ${p.Invoice.property_info.city}` }}</span>
                      </v-col>
                      <v-col cols="1" class="d-flex align-center justify-start px-0" v-if="is_inter_property_payment">
                        <div class="d-flex align-center">
                          <hb-unit-icon small color="#101318" :type="getUnitTypeLabel(p.Invoice.unit_info)" class="pr-1 pt-1 pb-1"/>
                          <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.Invoice && p.Invoice.unit_info.number }}</span>
                        </div>
                      </v-col>
                      <v-col cols="2" class="d-flex align-center justify-start pr-0 pl-4" v-if="is_inter_property_payment">
                        <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.Invoice && p.Invoice.date | formatDateTimeCustom('MMM DD, YYYY') }}</span>
                      </v-col>
                      <v-col :cols="is_inter_property_payment ?'2':'5'" :class="`d-flex align-center justify-start ${is_inter_property_payment ? 'px-0':'pl-4'}`">
                        <span class="ml-3 subtitle-2 font-weight-medium hb-link-secondary cursor-pointer" @click="showInvoiceDetails(p)">
                        #{{p.Invoice.number}}
                        </span>
                      </v-col>
                      <v-col :cols="is_inter_property_payment ?'2':'3'" class="d-flex align-center justify-end">
                        <span class="subtitle-2 font-weight-regular hb-text-night" v-if="!auctionPayment">{{invoiceTotal(p.Invoice) | formatMoney}}</span>
                      </v-col>
                      <v-col :cols="is_inter_property_payment ?'2':'3'" :class="`d-flex align-center justify-end pl-0 ${is_inter_property_payment?'':'ml-8'}`">
                          <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.amount | formatMoney}}</span>
                      </v-col>                       
                    </v-row>
                    <v-divider  v-if="p.Invoice"></v-divider>
                  </div>
                  <v-row :class="`d-flex pa-0 mx-0 pb-2 ${is_inter_property_payment ?'mr-3':'mr-9'}`">
                    <v-col cols="10" class="d-flex align-center justify-end px-0 pt-1" :class="auctionPayment ? 'pb-3' : 'pb-0'">
                      <span class="subtitle-1 font-weight-medium">Total Applied:</span>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-end px-0 pt-1 pb-0">
                      <span v-if="is_inter_property_payment" class="subtitle-1 font-weight-medium">{{ getInterPropertyAppliedPayments | formatMoney }}</span>
                      <span v-else class="subtitle-1 font-weight-medium">{{ payment.total_applied | formatMoney }}</span>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="d-flex pa-0 mx-0" v-if="!auctionPayment">
                    <v-col cols="10" class="d-flex align-center justify-end px-0 pt-0 pb-3">
                      <span class="subtitle-1 font-weight-medium">Applied to Reserve Balance:</span>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-end pl-0 pt-0 pb-3">
                      <span class="subtitle-1 font-weight-medium" style="color:#02AD0F;" v-if="!paymentVoided">{{ totalPrepayBalance | formatMoney }}</span>
                      <span class="subtitle-1 font-weight-medium" style="color:#02AD0F;" v-if="paymentVoided">{{ 0 | formatMoney }}</span>
                    </v-col>
                  </v-row> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-0 mt-4" v-if="payment.Refunds.length">
            <v-col class="pa-0">
              <v-card class="hb-elevation-medium hb-border-default">
                <v-card-title class="pt-3 pb-0" v-if="is_fatzebra_payment">
                  <span class="subtitle-1 font-weight-medium">Refund(s)</span>
                </v-card-title>
                <v-card-title class="pt-3 pb-0" v-else >
                  <span class="subtitle-1 font-weight-medium">Refund(s) and Reversal(s)</span>
                </v-card-title>
                  <v-card-text class="">
                    <v-row class="d-flex pa-0 mx-0">
                      <v-col class="d-flex pa-0" style="flex-direction:column;">
                        <div
                          v-for="p in payment.Refunds"
                          :key="p"
                        >
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0">
                              <span v-if="isFullRefund">
                                <span v-if="p.transaction_id">#{{p.transaction_id}} - </span> {{ REVERSAL_TYPES[p.type.toUpperCase()] }} {{ p.type == 'credit' ? "Reversal": "" }} on {{p.date | formatDateCustom('MMM DD, YYYY @ h:mma')}} - {{p.amount | formatMoney}}
                              </span>
                              <span v-else>
                                <span v-if="p.transaction_id">#{{p.transaction_id}} - </span> Partial {{ REVERSAL_TYPES[p.type.toUpperCase()] }} {{ p.type == 'credit' ? "Reversal": "" }} on {{p.date | formatDateCustom('MMM DD, YYYY @ h:mma')}} - {{p.amount | formatMoney}}
                              </span>
                              <div v-if="p.type !== REFUND.value && p.type !== REVERSALS.card.OFFLINE.value" class="letter-btn mb-1">
                                <hb-btn x-small color="secondary" :disabled="p.upload_src ? false : true" class="mr-2" @click="downloadGuide(p.upload_src)">Print/Download Letter</hb-btn>
                                <hb-btn x-small color="secondary" :disabled="isEmailDisabled" @click="showEmailConfirmation = true" class="mr-2">Email Letter</hb-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:right-actions>
        <span v-if="!auctionPayment && (shouldShowReversalOrVoidOption)">
          <hb-btn color="secondary" :loading="reversalLoading" @click="showReversal" v-if="payment.can_reverse && payment.is_settled && is_fatzebra_payment">
              Refund
            </hb-btn>
            <hb-btn color="secondary" :loading="reversalLoading" @click="showReversal" v-if="payment.can_reverse && payment.is_settled">
              Reversal
            </hb-btn>
            <hb-tooltip v-else-if="!payment.can_reverse && payment.is_settled" >
              <template v-slot:item>
                <hb-btn color="secondary" :loading="reversalLoading" @click="showReversal" :disabled="!payment.can_reverse">
                  Reversal
                </hb-btn>       
              </template>
              <template v-slot:body>
                Reversal Threshold has passed. Please contact your administrator to extend the threshold.
              </template>
            </hb-tooltip>
            <hb-btn color="secondary" :disabled="paymentVoided || is_forte_ach_payment || !hasPermissionToVoid" @click="showVoid = true" v-else>Void</hb-btn>
        </span>
        <!-- <hb-btn color="secondary" class="mx-2">Email</hb-btn> -->
        <hb-btn color="secondary" @click="goToReport(payment.id)" v-if="auctionPayment">Print</hb-btn>
        <hb-btn color="secondary" @click="download()" v-if="!auctionPayment" :loading="isLoading('download_receipt')">Print / Download Receipt</hb-btn>
        <hb-btn color="secondary" @click="sendReceiptModal = true,$refs?.sendReceipt?.fetchDataOnEvent()" v-if="!auctionPayment" :loading="isLoading($options.name)">Send Receipt</hb-btn>
        <hb-btn color="primary" @click="$emit('close')" v-if="auctionPayment">Close</hb-btn>
      </template>
    </hb-modal>

    <v-card v-else>
      <v-card-title class="d-flex align-center ma-0 pa-0 edit-payment-header-title-wrapper" v-if="hideHeader != true">
        <v-row no-gutters class="pl-4 pr-2">
          <v-col cols="10" class="d-flex align-center">
              <span class="font-weight-medium" v-if="auctionPayment">Auction Payment</span>
              <span class="font-weight-medium" v-else>Payment Details</span>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
              <hb-btn icon tooltip="Close" @click="$emit('close')" active-state-off hover-background-color="#CAEAEA">mdi-close</hb-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0 ma-0 hb-default-font-size">
        <span class="hb-text-night hb-default-font-size">
          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
          <status @resetStatus="errorClear('refund_payment')" v-if="errorHas('refund_payment')" :message="errorGet('refund_payment')" status="error"></status>
          <status @resetStatus="successClear('refund_payment')" v-if="successHas('refund_payment')" :message="successGet('refund_payment')" status="success"></status>
          <status @resetStatus="errorClear('download_receipt')" v-if="errorHas('download_receipt')" :message="errorGet('download_receipt')" status="error"></status>
          <status @resetStatus="successClear('download_receipt')" v-if="successHas('download_receipt')" :message="successGet('download_receipt')" status="success"></status>

          <div class="form-section" v-if="payment.AppliedPayments">
          <v-row v-if="auctionPayment" class="my-0 py-0 px-4 mt-2 mb-6" :class="{'pr-9' : showAside}">
            <hb-notification
                v-model="auctionPayment"
                v-if="auctionPayment"
                notDismissable
                type="success"
            >
                Auction Payment has been applied to outstanding invoices.
            </hb-notification>
          </v-row>
          <v-row v-if="is_inter_property_payment" class="my-0 py-0 px-4 mt-2 mb-6" :class="{'pr-9' : showAside}">
            <hb-notification
                v-model="is_inter_property_payment"
                v-if="is_inter_property_payment"
                notDismissable
                type="guidance"
            >
              This is an <span class="hb-font-body-medium">inter-property</span> payment.
            </hb-notification>
          </v-row>
            <v-row class="mx-0" v-if="!payment.is_settled && !paymentVoided" >
              <hb-notification type="caution" title="Warning:" :notDismissable="true" class="mb-4">
                <template > 
                  This transaction is not eligible for a refund.
                </template>
              </hb-notification>
            </v-row>
            <v-row class="mx-0" v-if="paymentVoided">
              <hb-notification type="success" title="Success:" :notDismissable="true" class="mb-4">
                <template > 
                  The payment has been voided successfully.
                </template>
              </hb-notification>
            </v-row>
            <v-row class="mx-0">
              <v-col cols="6" class="pa-0 pr-4">
                <v-card class="hb-elevation-medium hb-border-default">
                  <v-card-text class="pb-0" v-if="auctionPayment">
                    <span class="subtitle-1 font-weight-medium" style="line-height: 18px;">Auction Winner</span><br>
                    <span class="subtitle-1 font-weight-regular text-capitalize"> {{payment.Contact.first}} {{payment.Contact.last}} </span>
                  </v-card-text>
                  <v-card-title class="pa-4 pb-0" v-if="!auctionPayment">
                    <HbIcon v-if="isRentAsBusiness" color="#306FB6" :customSize="18" mdi-code="mdi-domain" />
                    <HbIcon v-else color="#306FB6" :customSize="18" mdi-code="mdi-account" />
                    <span class=" ml-1 subtitle-1 font-weight-regular text-capitalize hb-link-secondary"> {{payment.Contact.first}} {{payment.Contact.last}} </span>
                  </v-card-title>
                  <v-card-text class="px-4 pb-10" :class="auctionPayment ? 'pt-0' : ''">
                    <span class="subtitle-1" style="line-height: 18px;"> {{payment.Contact.email}}</span><br>
                    <span class="subtitle-1" style="line-height: 20px;" v-if="payment.Contact.Phones && payment.Contact.Phones.length > 0">{{payment.Contact.Phones[0].phone | formatPhone}} </span><br>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-card class="hb-elevation-medium hb-border-default">
                  <v-card-title class="pa-4 pb-0">
                    <v-col class="d-flex align-center pa-0">
                    <span class="subtitle-1 font-weight-regular text-capitalize" style="color:#02AD0F;"> Amount Paid</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-end pa-0">
                        <span class="subtitle-1 font-weight-regular hb-link" style="cursor: pointer;" @click="fetchPayment()"> Refresh</span>
                    </v-col>
                  </v-card-title>
                  <v-card-text class="px-4">
                    <div v-if="is_inter_property_payment">
                      <div class="subtitle-2" style="line-height: 18px;"> {{payment.InterPropertyPayment.sourcePayment.created | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma')}}</div>
                      <span class="font-weight-regular" style="line-height: 20px; font-size:24px;"> {{ payment.InterPropertyPayment.sourcePayment.amount | formatMoney}}</span>
                      <div class="subtitle-2" v-if="['card', 'ach'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0">{{payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_type | capitalize}}**** {{ payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_end}}</div>
                      <div class="subtitle-2" v-else-if="['directdebit'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0">Direct Debit**** {{ payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_end}}</div>
                      <div class="subtitle-2" v-else-if="['eftpos'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0">EFTPOS {{payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_type | capitalize}}</div>
                      <div class="subtitle-2" v-else-if="payment.InterPropertyPayment.sourcePayment.method == 'adjustment'">{{SUB_METHOD[payment.InterPropertyPayment.sourcePayment.sub_method] | capitalize}} Adjustment</div>
                      <div class="subtitle-2" v-else><span class="font-weight-medium">{{payment.InterPropertyPayment.sourcePayment.method | capitalize}} {{payment.InterPropertyPayment.sourcePayment.PaymentMethod.card_type}}</span></div>
                      <div class="subtitle-2" v-if="['cash', 'check'].indexOf(payment.InterPropertyPayment.sourcePayment.method) < 0 && payment.InterPropertyPayment.sourcePayment.transaction_id">Transaction ID: {{payment.InterPropertyPayment.sourcePayment.transaction_id}}</div>
                      <div class="subtitle-2">Property: {{payment.InterPropertyPayment.sourcePayment.Property.number}} - {{payment.InterPropertyPayment.sourcePayment.Property.Address.city}} </div>
                      <div class="subtitle-2" v-if="['card', 'ach', 'directdebit'].indexOf(payment.InterPropertyPayment.sourcePayment.method) >= 0"><template v-if="payment.InterPropertyPayment.sourcePayment.method.toLowerCase()==='ach'"><span class="font-weight-medium">{{payment.InterPropertyPayment.sourcePayment.method.toUpperCase()}}</span></template><template v-if="payment.InterPropertyPayment.sourcePayment.method.toLowerCase()!=='ach'"><span class="font-weight-medium">{{payment.InterPropertyPayment.sourcePayment.method | capitalize}}</span></template>
                        <template v-if="payment.InterPropertyPayment.sourcePayment.payment_source"> : {{ payment.InterPropertyPayment.sourcePayment.payment_source }}</template></div>                      
                      <div class="subtitle-2" v-if="payment.AcceptedBy && payment.InterPropertyPayment.sourcePayment.source && payment.InterPropertyPayment.sourcePayment.source.toLowerCase()==='in-store'">By: {{payment.AcceptedBy.first}} {{payment.AcceptedBy.last}}</div>
                      <div class="subtitle-2">Amount paid to property: <span class="hb-font-body-medium">{{ getAppliedPaymentsToProperty | formatMoney }}</span></div>
                    </div>
                    <div v-else>
                      <div class="subtitle-2" style="line-height: 18px;"> {{payment.created | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma')}}</div>
                      <span class="font-weight-regular" style="line-height: 20px; font-size:24px;"> {{payment.amount | formatMoney}}</span><br>
                      <div class="subtitle-2" v-if="['card', 'ach'].indexOf(payment.method) >= 0">{{payment.PaymentMethod.card_type | capitalize}}**** {{ payment.PaymentMethod.card_end}}</div>
                      <div class="subtitle-2" v-else-if="['directdebit'].indexOf(payment.method) >= 0">Direct Debit**** {{ payment.PaymentMethod.card_end}}</div>
                      <div class="subtitle-2" v-else-if="['eftpos'].indexOf(payment.method) >= 0">EFTPOS {{payment.PaymentMethod.card_type | capitalize}}</div>
                      <div class="subtitle-2" v-else-if="payment.method == 'adjustment'">{{SUB_METHOD[payment.sub_method] | capitalize}} Adjustment</div>
                      <div class="subtitle-2" v-else><span class="font-weight-medium">{{payment.method | capitalize}} {{payment.PaymentMethod.card_type}}</span></div>
                      <div class="subtitle-2" v-if="['cash', 'check'].indexOf(payment.method) < 0 && payment.transaction_id">Transaction ID : {{payment.transaction_id}}</div>
                      <div class="subtitle-2" v-if="['card', 'ach', 'directdebit'].indexOf(payment.method) >= 0"><template v-if="payment.method.toLowerCase()==='ach'"><span class="font-weight-medium">{{payment.method.toUpperCase()}}</span></template><template v-if="payment.method.toLowerCase()!=='ach'"><span class="font-weight-medium">{{payment.method | capitalize}}</span></template>
                        <template v-if="payment.payment_source"> : {{ payment.payment_source }}</template></div>
                      <div class="subtitle-2" v-if="payment.AcceptedBy && payment.source && payment.source.toLowerCase()==='in-store'">By : {{payment.AcceptedBy.first}} {{payment.AcceptedBy.last}}</div>
                    </div>
                    
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div class="font-weight-medium text-black mt-4" v-if="!auctionPayment">
              Payment is applied to the invoices listed:
            </div>
            <!-- <div>
              To refund an invoice select the checkbox.
            </div> -->
            <div class="font-weight-medium text-black mt-4" v-if="auctionPayment">Payment is applied to the items below</div>
            <v-row class="mx-0 mt-4">
              <v-col class="pa-0">
                <v-card class="hb-border-default" elevation="0">
                  <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell" style="flex-wrap: unset;">
                    <!-- <v-col cols="1" class="d-flex align-center">
                      <span class="ml-3 subtitle-2 font-weight-medium"></span>
                    </v-col> -->
                    <v-col cols="3" class="d-flex align-center justify-start pr-1" v-if="is_inter_property_payment">
                      <span class="subtitle-2 font-weight-medium">Property ID</span>
                    </v-col>
                    <v-col cols="1" class="d-flex align-center justify-start px-0" v-if="is_inter_property_payment">
                      <span class="subtitle-2 font-weight-medium">Space #</span>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-start pr-0 pl-4" v-if="is_inter_property_payment">
                      <span class="subtitle-2 font-weight-medium">Invoice Date</span>
                    </v-col>
                    <v-col :cols="is_inter_property_payment ?'2':'5'" :class="`d-flex align-center justify-start ${is_inter_property_payment ? 'px-0':'pl-4'}`">
                      <span class="ml-3 subtitle-2 font-weight-medium" v-if="auctionPayment">Description</span>
                      <span class="ml-3 subtitle-2 font-weight-medium" v-else>Invoice Number</span>
                    </v-col>
                    <v-col :cols="is_inter_property_payment ?'2':'3'" class="d-flex align-center justify-end">
                      <span class="subtitle-2 font-weight-medium" v-if="!auctionPayment">Invoice Total</span>
                    </v-col>
                    <v-col :cols="is_inter_property_payment ?'2':'3'" :class="`d-flex align-center justify-end pl-0 ${is_inter_property_payment?'':'ml-8'}`">
                        <span class="subtitle-2 font-weight-medium" v-if="auctionPayment">Amount Paid</span>
                        <span class="subtitle-2 font-weight-medium" v-else>Paid</span>
                    </v-col>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pa-0 ma-0 hb-default-font-size">
                    <div v-for="p in appliedPayments" :key="p.id">
                      <v-row :class="`d-flex pa-0 mx-0 ${ is_inter_property_payment && p.Invoice.property_id !== payment.property_id? 'disabled-row': ''}`" v-if="p.Invoice">
                        <!-- <v-col cols="1" class="d-flex align-center pt-1">
                          <v-checkbox hide-details dense class="mt-0" v-model="selectedInvoices" :value="p" column :disabled="!payment.is_settled"></v-checkbox>
                        </v-col> -->
                        <v-col cols="3" class="d-flex align-center justify-start pr-1" v-if="is_inter_property_payment">
                          <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.Invoice && `${p.Invoice.property_info.number} - ${p.Invoice.property_info.city}` }}</span>
                        </v-col>
                        <v-col cols="1" class="d-flex align-center justify-start px-0" v-if="is_inter_property_payment">
                          <div class="d-flex align-center">
                          <hb-unit-icon small :type="getUnitTypeLabel(p.Invoice.unit_info)" color="#101318" class="pr-1 pt-1" />
                            <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.Invoice && p.Invoice.unit_info.number }}</span>
                          </div>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-start pr-0 pl-4" v-if="is_inter_property_payment">
                          <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.Invoice && p.Invoice.date | formatDateTimeCustom('MMM DD, YYYY') }}</span>
                        </v-col>
                        <v-col :cols="is_inter_property_payment ?'2':'5'" :class="`d-flex align-center justify-start ${is_inter_property_payment ? 'px-0':'pl-4'}`">
                          <span class="ml-3 subtitle-2 font-weight-medium" :class="{ 'hb-link-secondary cursor-pointer' : !fromMasterVueFile }" @click="!fromMasterVueFile ? showInvoiceDetails(p) : ''">
                          #{{p.Invoice.number}}
                          </span>
                        </v-col>
                        <v-col :cols="is_inter_property_payment ?'2':'3'" class="d-flex align-center justify-end">
                          <span class="subtitle-2 font-weight-regular hb-text-night" v-if="!auctionPayment">{{invoiceTotal(p.Invoice) | formatMoney}}</span>
                        </v-col>
                        <v-col :cols="is_inter_property_payment ?'2':'3'" :class="`d-flex align-center justify-end pl-0 ${is_inter_property_payment?'':'ml-8'}`">
                            <span class="subtitle-2 font-weight-regular hb-text-night">{{ p.amount | formatMoney}}</span>
                        </v-col>                       
                      </v-row>
                      <v-divider  v-if="p.Invoice"></v-divider>
                    </div>
                    <v-row :class="`d-flex pa-0 mx-0 pb-2 ${is_inter_property_payment ?'mr-3':'mr-9'}`">
                      <v-col cols="10" class="d-flex align-center justify-end px-0 pt-1" :class="auctionPayment ? 'pb-3' : 'pb-0'">
                        <span class="subtitle-1 font-weight-medium">Total Applied:</span>
                      </v-col>
                      <v-col cols="2" class="d-flex align-center justify-end px-0 pt-1 pb-0">
                        <span v-if="is_inter_property_payment" class="subtitle-1 font-weight-medium">{{ getInterPropertyAppliedPayments | formatMoney }}</span>
                        <span v-else class="subtitle-1 font-weight-medium">{{ payment.total_applied | formatMoney }}</span>
                      </v-col>
                    </v-row>
                    <!-- <v-row class="d-flex pa-0 mx-0" v-if="!auctionPayment">
                      <v-col cols="10" class="d-flex align-center justify-end px-0 pt-0 pb-3">
                        <span class="subtitle-1 font-weight-medium">Applied to Reserve Balance:</span>
                      </v-col>
                      <v-col cols="2" class="d-flex align-center justify-end pl-0 pt-0 pb-3">
                        <span class="subtitle-1 font-weight-medium" style="color:#02AD0F;" v-if="!paymentVoided">{{ totalPrepayBalance | formatMoney }}</span>
                        <span class="subtitle-1 font-weight-medium" style="color:#02AD0F;" v-if="paymentVoided">{{ 0 | formatMoney }}</span>
                      </v-col>
                    </v-row> -->
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mx-0 mt-4" v-if="payment.Refunds.length">
              <v-col class="pa-0">
                <v-card class="hb-elevation-medium hb-border-default">
                  <v-card-title class="pt-3 pb-0">
                    <span class="subtitle-1 font-weight-medium">Refund(s) and Reversal(s)</span>
                  </v-card-title>
                    <v-card-text class="">
                      <v-row class="d-flex pa-0 mx-0">
                        <v-col class="d-flex pa-0" style="flex-direction:column;">
                          <div
                            v-for="p in payment.Refunds"
                            :key="p"
                          >
                            <v-row class="ma-0 pa-0">
                              <v-col cols="12" class="ma-0 pa-0">
                                <span v-if="isFullRefund">
                                  <span v-if="p.transaction_id">#{{p.transaction_id}} - </span> {{ REVERSAL_TYPES[p.type.toUpperCase()] }} {{ p.type == 'credit' ? "Reversal": "" }} on {{p.created_at | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma')}} - {{p.amount | formatMoney}}
                                </span>
                                <span v-else>
                                  <span v-if="p.transaction_id">#{{p.transaction_id}} - </span> Partial {{ REVERSAL_TYPES[p.type.toUpperCase()] }} {{ p.type == 'credit' ? "Reversal": "" }} on {{p.created_at | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma') }} - {{p.amount | formatMoney}}
                                </span>
                                <div v-if="p.type !== REFUND.value && p.type !== REVERSALS.card.OFFLINE.value" class="letter-btn mb-1">
                                  <hb-btn x-small color="secondary" :disabled="p.upload_src ? false : true" class="mr-2" @click="downloadGuide(p.upload_src)">Print/Download Letter</hb-btn>
                                  <hb-btn x-small color="secondary" :disabled="isEmailDisabled" @click="showEmailConfirmation = true" class="mr-2">Email Letter</hb-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

        </span>
        <hb-bottom-action-bar @close="$emit('close')" border-bottom-radius>
          <template v-slot:right-actions>
            <span v-if="!auctionPayment && (shouldShowReversalOrVoidOption)">
              <hb-btn color="secondary" :loading="reversalLoading" @click="showReversal" v-if="payment.can_reverse && payment.is_settled">
                Reversal
              </hb-btn>
              <hb-tooltip v-else-if="!payment.can_reverse && payment.is_settled" >
                <template v-slot:item>
                  <hb-btn color="secondary" :loading="reversalLoading" @click="showReversal" :disabled="!payment.can_reverse">
                    Reversal
                  </hb-btn>       
                </template>
                <template v-slot:body>
                  Reversal Threshold has passed. Please contact your administrator to extend the threshold.
                </template>
              </hb-tooltip>
              <hb-btn color="secondary" :disabled="paymentVoided || is_forte_ach_payment || !hasPermissionToVoid" @click="showVoid = true" v-else>Void</hb-btn>
            </span>
            <!-- <hb-btn color="secondary" class="mx-2">Email</hb-btn> -->
            <hb-btn color="secondary" @click="goToReport(payment.id)" v-if="auctionPayment">Print</hb-btn>
            <hb-btn color="secondary" @click="download()" v-if="!auctionPayment" :loading="isLoading('download_receipt')">Print / Download Receipt</hb-btn>
            <hb-btn color="secondary" @click="sendReceiptModal = true,$refs?.sendReceipt?.fetchDataOnEvent()" v-if="!auctionPayment" :loading="isLoading($options.name)">Send Receipt</hb-btn>
            <hb-btn color="primary" @click="$emit('close')" v-if="auctionPayment">Close</hb-btn>
          </template>
        </hb-bottom-action-bar>
      </v-card-text>
    </v-card>

    <hb-modal
      confirmation
      footerOff
      v-model="sendReceiptModal"
      size="medium"
      title="Send Receipt"
      title-icon="mdi-user-actions-custom-1"
      show-help-link
      @close="$refs.sendReceipt.closeModal()"
    >
      <template v-slot:content>
        <send-receipt :payment_id="payment_id" ref="sendReceipt" :contact="payment.Contact" @close="sendReceiptModal = false"></send-receipt>
      </template>
    </hb-modal>

    <email-document v-if="showEmailDocument" v-model="showEmailDocument" />

    <reversal
      v-model="refundFlag"
      v-if="refundFlag"
      :isPartialRefund="isPaymentRefunded && !isFullRefund"
      :paymentMethod="fetchPaymentMethod"
      @close="refundFlag = false"
      :payments="filterPaymentInvoices"
      :is_fatzebra_payment=this.is_fatzebra_payment
      :selectedInvoices.sync="this.selectedInvoices"
      :payment="this.payment"
      :refundOption="refund_option"
      :isOnlyNSF="!payment.can_reverse && payment.method == 'check'"
    /> 

    <reversal-confirmation 
      v-model="reversalConfirmation"
      v-if="reversalConfirmation"
      @close="reversalConfirmation = false"
      :payment="payment"
      :reversal_meta="reversal"
    />

    <hb-modal v-model="showVoid" size="medium" title="Confirmation" confirmation show-help-link>
      <template v-slot:content>
          <div class="pa-4">
              Are you sure you want to continue?
          </div>
      </template>
      <template v-slot:right-actions>
          <hb-btn color="primary" @click="processVoid">Confirm</hb-btn>
      </template>
    </hb-modal>

    <hb-modal v-if="showEmailConfirmation" v-model="showEmailConfirmation" size="medium" title="Email Confirmation" confirmation show-help-link>
      <template v-slot:content>
          <div class="pa-4">
            This email will be sent to the tenant, are you sure you want to continue ?
          </div>
      </template>
      <template v-slot:right-actions>
          <hb-btn color="primary" :loading="isLoading($options.name)" :disabled="isLoading($options.name)"  @click="showEmailConfirmationYes()">Confirm</hb-btn>
      </template>
    </hb-modal>
    
  </div>

</template>

<script type="text/babel">
import REVERSAL from "@/constants/reversal.js";
import Dropdown from '../assets/Dropdown.vue';
import DropdownMenu from '../assets/DropdownMenu.vue';
import Datepicker from 'vuejs-datepicker';
import SendReceipt from '../assets/SendReceipt.vue';
import Status from '../includes/Messages.vue';
import Loader from '../assets/CircleSpinner.vue';
import api from '../../assets/api.js';
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import Reversal from './Reversal.vue';
import EmailDocument from './EmailDocument.vue';
import ReversalConfirmation from './ReversalConfirmation.vue';
import ADJUSTMENT from "@/constants/adjustment.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import { PAYMENTS } from '@/constants/payments.js';

export default {
  name: "EditPayment",
  mixins: [notificationMixin],
  data() {
    return {
      payment: {
        Property: {},
        Lease: {},
        PaymentMethod: {},
        amount: '',
        applied: '',
        AppliedPayments: [],
        Refunds: [],
        date: "",
        id:'',
        lease_id: "",
        method:'',
        notes:"",
        number:"",
        payment_methods_id: "",
        total_applied:0,
        payment_remaining:0,
        transaction_id: "",
        type:"",
        status_desc: '',
        is_auction_payment: false,
        source: '',
        payment_source: '',
      },
      void_amount: null,
      refund: null,
      reason: '',
      selectedInvoices: [],
      refundFlag:false,
      refund_option: '',
      showVoid: false,
      paymentVoided: false,
      sendReceiptModal: false,
      reversalConfirmation: false,
      showEmailDocument: false,
      auctionPayment: false,
      is_auctioned_lease_payment: false,
      is_forte_ach_payment: false,
      is_fatzebra_payment:false,
      is_inter_property_payment: false,
      showEmailConfirmation:false,
      isEmailDisabled:false
    }
  },
  computed:{
    ...mapGetters({
      isAdmin: 'authenticationStore/isAdmin',
      reversal: 'paymentsStore/getReversal',
      reversalLoading: 'paymentsStore/getReversalLoading',
      reversalError: 'paymentsStore/getReversalError',
      hasPermission: 'authenticationStore/rolePermission',
      properties: "propertiesStore/properties",
    }),
    hasPermissionToVoid() {
      return this.hasPermission('card_void_permission');
    },
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    modalDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    totalPrepayBalance() {
      if(this.payment.AppliedPayments.length > 0) {
        let total_refund = this.payment.Refunds.reduce(function(a, b){
          return a + parseFloat(b.amount) || 0;
        }, 0);
               
        return this.payment.amount - this.payment.total_applied - total_refund
      } else {
        return 0
      }
    },
    shouldShowReversalOrVoidOption() {
      return this.payment.total_applied > 0 && this.payment.status > 0;
    },
    getInterPropertyAppliedPayments() {
      return this.payment.InterPropertyPayment?.appliedPayments.reduce((acc, item) => acc + item.amount, 0);
    },
    isPaymentRefunded() {
      if(this.is_inter_property_payment) {
        const amountApplied = this.payment.InterPropertyPayment.appliedPayments.reduce((acc, item) => acc + item.amount, 0);
        const sourceAmount = this.payment.InterPropertyPayment.sourcePayment.amount;
        return amountApplied == sourceAmount ? false : true;
      }
      return this.payment?.Refunds.length > 0;
    },
    isFullRefund() {
      if(this.payment?.Refunds?.length > 1) return false;
      const appliedPayments = this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments : this.payment.AppliedPayments;
      const amountApplied = appliedPayments.reduce((acc, item) => acc + item.amount, 0);
      return amountApplied > 0 ? false : true;
    },
    appliedPayments(){
      return this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments: this.payment.AppliedPayments
    },
    getAppliedPaymentsToProperty(){
      let appliedAmounts = this.payment.InterPropertyPayment.appliedPayments.filter(x=>x.Invoice?.property_id === this.payment.property_id).map(x=> x.amount);
      if(appliedAmounts?.length > 0){
        return appliedAmounts.reduce((total, amt) => total + amt);
      } else {
        return 0;
      }
    },
    isRentAsBusiness(){
      return this.payment && this.payment.Contact && this.payment.Contact.rent_as_business ? true : false; 
    },
    filterPaymentInvoices() {
      return this.is_inter_property_payment
        ? this.payment.InterPropertyPayment.appliedPayments.filter(item => item.payment_id === this.payment.id)
        : this.payment.AppliedPayments;
    },
    fetchPaymentMethod() {
      return this.is_inter_property_payment ? this.payment.InterPropertyPayment.sourcePayment.method : this.payment.method;
    }
  },
  components: {
    Dropdown,
    DropdownMenu,
    Datepicker,
    Status,
    Loader,
    SendReceipt,
    Reversal,
    ReversalConfirmation,
    EmailDocument
  },
  async created(){
    this.REVERSAL_TYPES = REVERSAL.REVERSAL_TYPES;
    this.REFUND = REVERSAL.REFUND;
    this.REVERSALS = REVERSAL.REVERSALS;
    this.SUB_METHOD = ADJUSTMENT.SUB_METHOD;
    await this.fetchPayment();
    EventBus.$on('show_reversal_confirmation', this.showReversalConfirmation);
    EventBus.$on('show_void_confirmation', this.showVoidConfirmation);
    EventBus.$on('setInvoices', this.setInvoices);
    EventBus.$on('selectAllInvoices', this.selectAllInvoices);
    EventBus.$on('reversal_process_update', this.reversalProcessUpdate);
    EventBus.$on('disable_email_letter', this.disableEmailLetter);
    this.PAYMENTS = PAYMENTS;
  },
  filters:{
  },
  methods: {
    ...mapMutations({
      setReversalObject: 'paymentsStore/setReversalObject'
    }),
    showReversalConfirmation() {
      this.reversalConfirmation = true;
    },
    showVoidConfirmation() {
      this.showVoid = true;
    },
    showInvoiceDetails(payment) {
      EventBus.$emit('show_invoice', payment.Invoice.id);
    },
    goToReport(id){
      let routeData = this.$router.resolve("/reports.html?type=receipt&auction_payment=1&request_id=" + id);
      window.open(routeData.href, '_blank')
    },
    async processVoid() {
      api.post(this, api.PAYMENTS + this.payment.id + '/void').then(r => {
        this.paymentVoided = true;
        this.$emit('refetch');
        this.fetchPayment();
        this.showMessageNotification({ type: 'success', description: 'The payment has been voided successfully.' })
      });
      this.showVoid = false;
      this.refundFlag = false;
    },
    calculateRefundInformation() {
      var invoices = [];
      var total_refund = 0;

      for(var i=0; i<this.selectedInvoices.length; i++) {
        const refundAmount = +this.selectedInvoices[i].refund_amount || this.selectedInvoices[i].amount;

        total_refund = (+total_refund + refundAmount).toFixed(2);
        invoices.push({
          invoices_payment_id: this.selectedInvoices[i].id,
          amount: refundAmount,
          number: this.selectedInvoices[i].number,
          invoice_amount:this.selectedInvoices[i].amount,
        });
      }

      var data = {
        amount: parseFloat(total_refund).toFixed(2),
        // reason: 'un-apply refund',
        is_prepay: false,
        invoices
      }

      if (this.refund_option.value === 0) {
        data.is_prepay = true
      }

      this.setReversalObject({ reversal: { ...this.reversal, ...data } });
    },
    showReversal() {
      this.selectedInvoices.forEach((inv, i) => {
        this.selectedInvoices[i].refund_amount = this.selectedInvoices[i].amount
      });  
      this.calculateRefundInformation();
      this.refundFlag = true
    },
    getRefundOptions() {
      let text = ''
      if (['card', 'ach'].indexOf(this.payment.method) >= 0) {
        text = this.payment.PaymentMethod.card_type + '****' +  this.payment.PaymentMethod.card_end
      } else {
        text = this.payment.method
      }

      this.refund_option = {
        text,
        value: this.payment.PaymentMethod.id  || 1
      }
    },
    promotionTotal(invoice){
      return invoice && invoice.total_discounts;
    },
    taxSum(invoice){
      return invoice && invoice.total_tax;
    },
    subTotal(invoice){
      return invoice && invoice.subtotal;
    },
    invoiceTotal(invoice){
      return this.taxSum(invoice) + this.subTotal(invoice) - this.promotionTotal(invoice);
    },
    async fetchPayment(){
      if(!this.payment_id) return;
      await api.get(this, api.GET_PAYMENT + this.payment_id + "?unit_info=true").then(async (r) => {

        this.payment = r.payment;
        this.payment.Property = r.payment.Property
        this.getRefundOptions();
        this.selectedInvoices = [];
        this.auctionPayment = this.payment.is_auction_payment;
        this.is_auctioned_lease_payment = this.payment.is_auctioned_lease_payment;
        this.is_inter_property_payment = this.payment.is_inter_property_payment;
 
        
          let c = await api.get(this, api.PROPERTIES + this.payment.property_id +  '/connections');
          if(c.connections?.length > 0) {
            let forteConnection = c.connections.find(c=> c.name ==='forte');
            this.is_forte_ach_payment = forteConnection ? true:false
          } else {
            this.is_forte_ach_payment = false;
          }
         
          if(c.connections?.length > 0) {
            let fatZebraConnection = c.connections.find(c=> c.name ==='fatzebra');
            this.is_fatzebra_payment =  fatZebraConnection? true : false;
          }

        
        // Removed the check which was hiding the popup when the full refund is initiated.   
        // if(r.payment.total_applied === 0) {
        //   this.dialog = false
        // }
      });
    },
    cancelUnapply(p){
        this.$refs['unapplyDropdown_' + p.id].away();
    },
    unapplyPayment(p){

        var data = {
            id: p.id
        };
        api.delete(this, api.PAYMENTS + 'apply/', p.id).then(r => {
            this.$emit('refetch');
            this.fetchPayment();
        });
    },
    deletePayment(){

        if(this.payment.AppliedPayments.length){
            alert("In order to delete this payment you must unapply it from all invoices");
            return;
        }

        api.delete(this, api.PAYMENTS, this.payment_id).then(r => {
            this.$emit('refetch');
            this.$emit('cancel');
        });
    },
    async download(){
        let url = api.REPORTS + "download-pdf";

        var data = {
            type: 'receipt',
            format: 'pdf',
            request_id: this.payment_id,
            browser_time: this.$options.filters.formatDateTimeCustom(this.payment.created, 'MMM DD, YYYY @ h:mma')
        }

        let response = await api.post(this, url, data, 'download_receipt');

        const arr = new Uint8Array(response.data);
        var blob = new Blob([arr], {type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `receipt_${moment().format('x')}`;
        link.click();
        

        //this.$store.commit('notificationsStore/downloadReceipt', response);
    },
    showWebView() {
      window.open('/reports/web/payment?id=' + this.payment.id, '_blank');
    },
    setInvoices(invoices) {
      this.selectedInvoices = invoices; 
      this.calculateRefundInformation();
    },
    selectAllInvoices() {
      const appliedPayments = this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments : this.payment.AppliedPayments;
      this.setInvoices(appliedPayments);
    },
    refetchPaymentInfo() {
      this.$emit('refetch');
      this.fetchPayment();
      this.refundFlag = false
    },
    propertyNumber(property_id){
      let property = this.properties.find(x=>x.id === property_id);
      return property ? `${property.number} - ${property.Address.city}`:'';
    },
    async downloadGuide(name){
      if(name){
        const link = document.createElement('a');
        link.href = name;
        link.target = '_blank';
        link.click();
      }
    },
    async showEmailConfirmationYes(){
      try {
        const { id: payment_id,  Refunds } = this.payment;
        this.isEmailDisabled = true;
        await api.post(this, `${api.EMAIL_REFUND}${payment_id}/refund/${Refunds[0].id}/send-email`, {});
        this.showMessageNotification({ type: 'success', description: 'Email letter will be send to you soon.' }) 
      } catch (error) {
      }
      this.showEmailConfirmation = false;
    },
    reversalProcessUpdate(reversal){
        if(this.payment.Refunds?.length > 0){
          let index = this.payment.Refunds.indexOf(x=> x.id === reversal.refund_id);
          if(index === -1) return;
          this.payment.Refunds[index].upload_src = reversal.upload_src;
          this.payment.Refunds[index].upload_id = reversal.upload_id;
          this.isEmailDisabled = false;
        }
    },
    disableEmailLetter(flag){
        this.isEmailDisabled = flag;  
    },
  },
  destroyed(){
    EventBus.$off('show_reversal_confirmation', this.showReversalConfirmation);
    EventBus.$off('setInvoices', this.setInvoices);
    EventBus.$off('selectAllInvoices', this.selectAllInvoices);
    EventBus.$off('reversal_process_update', this.reversalProcessUpdate);
    EventBus.$off('disable_email_letter', this.disableEmailLetter);
  },
  watch: {
    payment_id(){
      this.fetchPayment();
    },
    errorList () {
      if(this.errorHas(this.$options.name)) {
        this.refundFlag = false
      }
    },
    reversalError(newVal) {      
      if(newVal === '') {
        this.refetchPaymentInfo();
      } else if(newVal?.length) {
        this.showMessageNotification({type:'error', list: [newVal] })
      }
    }
  },
  props: ['payment_id', 'value', 'hideHeader', 'modal', 'fromMasterVueFile']
}

</script>
<style scoped>
  ul.dropdown-menu{
    max-height: 400px;
  }
  .inv-header-cell{
    background: #F4F6F8;
  }
  .hb-inv-number {
    color: #306FB6;
  }
  span{
    color: #101318;
  }

  .hb-link-secondary {
    color: #306FB6 !important;
    text-decoration:none;
  }
  .hb-link {
    color: #00848E;
    text-decoration:none;
  }
  .form-section{
    background: #F9FAFB;
    border: 0px;
    margin-bottom: 0px;
    padding-bottom: 15px;
  }
  .letter-btn {
    float: right;
  }
  .disabled-row {
    background: #F9FAFB;
    opacity: 0.6;
  }

  .edit-payment-header-title-wrapper {
    background: #E0F5F5;
    height: 52px;
  }
</style>
