<template>
  <span id="component-assets-send-receipt">
    <div class="pa-5 ma-0">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)"
        status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
        :message="successGet($options.name)" status="success"></status>
      <div class="pb-4 hb-font-header-3">
        How do you want to send the receipt?
      </div>
      <hb-checkbox v-model="textCheck" label="Text" class="mb-2" @change="clearError('Text')">
      </hb-checkbox>
      <hb-text-field   :readonly="!textCheck" class="pa-0 ma-0 mr-4" hide-details v-model="formatPhoneLocal" v-validate="'required'"
        :error="errors.has('receipt.sms')" data-vv-name="sms" data-vv-as="Text" data-vv-scope="receipt" />
      <hb-checkbox v-model="emailCheck" label="Email" class="mt-4 mb-1" @change="clearError('Email')">
      </hb-checkbox>
      <hb-text-field class="pa-0 ma-0 mr-4" id="input-assets-send-receipt-email" name="email" placeholder="Enter Email"
        v-model="receipt.email"
        :readonly="!emailCheck"
        v-validate="{ required: true, max: 45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
        data-vv-name="email" data-vv-as="Email" :error="errors.has('receipt.email')" style="margin-top: 6px;"
        data-vv-scope="receipt"></hb-text-field>
    </div>
    <hb-bottom-action-bar @close="closeModal">
      <template v-slot:right-actions>
        <hb-btn color="secondary" @click="takeAction()" :disabled="!emailCheck && !textCheck" :loading="loading">Send
          Receipt</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </span>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import Status from '../../components/includes/Messages.vue';
import { mapGetters } from "vuex";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from '../../mixins/notificationMixin.js';

export default {
  name: "SendReceipt",
  mixins: [notificationMixin],
  data() {
    return {
      payment: {},
      receipt: {
        phone: null,
        email: null
      },
      emailCheck: false,
      textCheck: false,
      loading: false,
    };
  },
  components: {
    Status,
  },
  props: ["payment_id", "contact"],
  expose: ['closeModal', 'fetchDataOnEvent'],
  computed: {
    ...mapGetters({
      payments: "onBoardingStore/payments"
    }),

    formatPhoneLocal: {
      get() {
        return this.receipt.phone ? this.$options.filters.formatPhone(this.receipt.phone) : null;
      },
      set(value) {
        // Set the value of receipt.phone (reverse formatting or plain assignment)
        this.receipt.phone = value;
      },
    },
  },
  async created() {
    this.fetchDataOnEvent()
  },
  methods: {
    clearError(method) {
      if (method === 'Email') {
        this.$validator.reset('receipt.email');
      } if (method === 'Text') {
        this.$validator.reset('receipt.sms');
      }
    },
    async fetchDataOnEvent() {
      await this.fetchData();
      this.receipt.email = (this.payment.Contact && this.payment.Contact.email) ? this.payment.Contact.email : '';
      this.receipt.phone = (this.payment.Contact && this.payment.Contact.Phones && this.payment.Contact.Phones.length) ? this.payment.Contact.Phones[0].phone : '';
    },
    closeModal() {
      this.$emit('close')
      this.resetChecks()
      this.errorClear(this.$options.name);
      this.errors.clear();
      this.$validator.reset();
      this.$validator.errors.clear();
    },
    resetChecks() {
      this.emailCheck = false
      this.textCheck = false
    },
    async takeAction() {
      this.loading = true
      const promises = [];
      if (this.emailCheck) {
        promises.push(this.sendEmail().then(() => ({ type: 'email', status: 'fulfilled' })).catch(error => ({ type: 'email', status: 'rejected', error })));
      }
      if (this.textCheck) {
        promises.push(this.sendText().then(() => ({ type: 'text', status: 'fulfilled' })).catch(error => ({ type: 'text', status: 'rejected', error })));
      }
      try {
        const results = await Promise.allSettled(promises);
        console.log(results, "results");
        results.forEach(result => {
          if (result.value.status === 'fulfilled') {
            if (result.value.type === 'email') {
              this.emailCheck = false
            } else if (result.value.type === 'text') {
              this.textCheck = false
            }
          }
        });
        // Emit 'close' only if both functions succeed
        const successTypes = results
          .filter(result => result.value.status === 'fulfilled')
          .map(result => result.value.type);
        // Construct the dynamic message
        let description;
        if (successTypes.length === 2) {
          description = 'The receipt has been sent via both email and text.';
        } else if (successTypes.length === 1) {
          description = `The receipt has been sent via ${successTypes[0]}.`;
        }
        if (successTypes.length > 0) {
          this.loading = false
          this.showMessageNotification({
            type: 'success',
            description: description
          });
        }
        // Emit 'close' only if both email and SMS succeeded
        const allSuccess = results.every(result => result.value.status === 'fulfilled');
        if (allSuccess) {
          this.loading = false
          this.$emit('close');
          this.resetChecks()
        }
      } catch (error) {
        this.loading = false
        console.error("An error occurred:", error);
      }
    },
    async fetchData() {
      let payment_id = this.payment_id || null;
      if (!payment_id) return;
      let r = await api.get(this, api.PAYMENTS + payment_id + "/receipt");
      this.payment = r.payment;
    },
    async sendEmail() {
      let status = await this.$validator.validate('receipt.email');
      if (!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        this.loading = false
        throw new Error('Validation failed'); // Explicitly throw an error to reject the promise
      }

      let payment_id = this.payment_id || null;
      if (!payment_id) {
        this.showMessageNotification({ type: 'error', description: 'There is no payment id associated with this payment. Receipt cannot be sent.' });
        this.loading = false
        throw new Error('No payment ID'); // Explicitly throw an error
      }

      var data = {
        email: this.receipt.email,
        browser_time: this.$options.filters.formatDateTimeCustom(this.payment.created, 'MMM DD, YYYY @ h:mma')
      };

      try {
        let response = await api.post(this, api.PAYMENTS + payment_id + "/email", data);
        if (response && response.msg) {
          // this.showMessageNotification({ type: 'success', description: response.msg });
          return true; // Indicate success
        } else {
          this.loading = false
          throw new Error('No response message'); // Throw error for missing response
        }
      } catch (err) {
        let r = { error: err };
        this.loading = false
        this.errorSet(this.$options.name, 'Email could not be sent.');
        EventBus.$emit("paymentReader", r);
        throw err; // Throw the error to reject the promise
      }
    },
    async sendText() {
      let status = await this.$validator.validate('receipt.sms');
      if (!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        this.loading = false
        throw new Error('Validation failed'); // Explicitly throw an error to reject the promise
      }

      let payment_id = this.payment_id || null;
      if (!payment_id) {
        this.showMessageNotification({ type: 'error', description: 'There is no payment id associated with this payment. Receipt cannot be sent.' });
        this.loading = false
        throw new Error('No payment ID'); // Explicitly throw an error
      }

      var data = {
        to_phone: this.receipt.phone,
        browser_time: this.$options.filters.formatDateTimeCustom(this.payment.created, 'MMM DD, YYYY @ h:mma')
      };

      try {
        let response = await api.post(this, api.PAYMENTS + payment_id + "/sms", data);
        if (response && response.msg) {
          // this.showMessageNotification({ type: 'success', description: response.msg });
          return true; // Indicate success
        } else {
          this.loading = false
          throw new Error('No response message'); // Throw error for missing response
        }
      } catch (err) {
        let r = { error: err };
        EventBus.$emit("paymentReader", r);
        this.loading = false
        this.errorSet(this.$options.name, 'Text message could not be sent.');
        throw err; // Throw the error to reject the promise
      }
    }
  },
};
</script>

<style scoped></style>
